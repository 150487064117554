let backendURL = process.env.REACT_APP_BACKEND_URL
if (backendURL == null){
    backendURL = "http://localhost:8000"
    //backendURL = "https://storybook-fastapi-8879a9fd0b0f.herokuapp.com"
}

export const StoryAPI = {
	postStory: async function (character: string, helper: string, villain: string, setting: string, interests: string[], storyLength: string, storyAge: string) {
    let storyURL = backendURL + "/story";

    let response = await fetch(storyURL, {
      method: "POST",
      body: JSON.stringify({
        character: character,
        helper: helper,
        villain: villain,
        setting: setting,
        interests: interests,
        story_length: storyLength,
        story_age: storyAge,
      }),
      headers: {
        "Content-Type": "application/json",
      }
    });

    let responseJson = await response.json();
    return responseJson;
  },
  postStoryPregen: async function (character: string, helper: string, villain: string, setting: string, interests: string[], storyLength: string, storyAge: string,
    storySection1: string, storySection2: string, storySection3: string, storySection4: string, storySection5: string, 
    cliffhangerQuestion: string, storyCliffhangerOption1: string, storyCliffhangerOption2: string, storyCliffhangerOption3: string, 
    storyTitle: string, chapterTitle: string, chapterSummary: string, chapterFont: string
    ) {

    console.log('creating story and first chapter using pregenerated story, chapterFont: ', chapterFont);

    let storyURL = backendURL + "/story-pregen";

    let response = await fetch(storyURL, {
      method: "POST",
      body: JSON.stringify({
        character: character,
        helper: helper,
        villain: villain,
        setting: setting,
        interests: interests,
        story_length: storyLength,
        story_age: storyAge,
        story_section1: storySection1,
        story_section2: storySection2,
        story_section3: storySection3,
        story_section4: storySection4,
        story_section5: storySection5,
        cliffhanger_question: cliffhangerQuestion,
        story_cliffhanger_option1: storyCliffhangerOption1,
        story_cliffhanger_option2: storyCliffhangerOption2,
        story_cliffhanger_option3: storyCliffhangerOption3,
        story_title: storyTitle,
        chapter_title: chapterTitle,
        chapter_summary: chapterSummary,
        chapter_font: chapterFont
      }),
      headers: {
        "Content-Type": "application/json",
      }
    });

    let responseJson = await response.json();
    return responseJson;
  },
  postChapter: async function (storyId: number, chapterFont: string) {
    let storyURL = backendURL + "/chapter";

    let response = await fetch(storyURL, {
      method: "POST",
      body: JSON.stringify({
        story_id: storyId,
        chapter_font: chapterFont
      }),
      headers: {
        "Content-Type": "application/json",
      }
    });

    let responseJson = await response.json();
    return responseJson;
  },
  getStory: async function (uuid: string) {
    let storyURL = backendURL + "/story/" + uuid;

    let response = await fetch(storyURL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      }
    });

    let responseJson = await response.json();
    return responseJson;
  },
  getChapter: async function (storyId: number, chapter: number) {
    let storyURL = backendURL + "/story/" + storyId.toString() + "/chapter/" + chapter.toString();

    let response = await fetch(storyURL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      }
    });

    let responseJson = await response.json();
    return responseJson;
  },
  putOption: async function (storyId: number, chapter: number, option: number, ratingChapter: number, ratingFont: number, storyFont: string) {
    let storyURL = backendURL + "/option";

    await fetch(storyURL, {
      method: "PUT",
      body: JSON.stringify({
        story_id: storyId,
        chapter: chapter,
        option: option,
        rating_chapter: ratingChapter,
        rating_font: ratingFont,
        chapter_font: storyFont,
      }),
      headers: {
        "Content-Type": "application/json",
      }
    });

    return;
  },
  postFeedback: async function (storyId: number, email: string, userAge: number, feedback: string, rating: number) {
    let storyURL = backendURL + "/feedback";

    let response = await fetch(storyURL, {
      method: "POST",
      body: JSON.stringify({
        story_id: storyId,
        email: email,
        user_age: userAge,
        feedback: feedback,
        rating: rating,
      }),
      headers: {
        "Content-Type": "application/json",
      }
    });

    let responseJson = await response.json();
    return responseJson;
  },
  postWordStory: async function (storyText: string) {
    let storyURL = backendURL + "/word-story";

    let response = await fetch(storyURL, {
      method: "POST",
      body: JSON.stringify({
        story_text: storyText,
      }),
      headers: {
        "Content-Type": "application/json",
      }
    });

    let responseJson = await response.json();
    return responseJson;
  },
}