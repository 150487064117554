import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Modal, Button } from 'react-bootstrap';
import { useAppContext } from '../../AppContext';
import { RotatingTriangles } from 'react-loader-spinner';
import { StoryAPI } from "../../api/StoryAPI";
import { getStorySection1, getStorySection2, getStorySection3 } from './StoryContent';
import { getStorySection4, getStorySection5, getStorySectionCliffhanger } from './StoryContent';
import './Story.css';
import './StoryApp.css'; 


const characters = [
  { name: "Nat", image: `${process.env.PUBLIC_URL}/images/nat_fs2.png` },
  { name: "Mia", image: `${process.env.PUBLIC_URL}/images/mia_fs2.png` },
  { name: "Alex", image: `${process.env.PUBLIC_URL}/images/alex_fs2.png` },
];

const Story: React.FC = () => {
  
  const { storyLength, storyAge, character, setting, villain, helper, interests, chapter, title, storyId, isFullScreen,
     setChapter, setTitle, setStoryId, removeInterest, toggleFullScreen } = useAppContext();

  const [storyChapterTitle, setStoryChapterTitle] = useState<string>("");
  const [storyText1, setStoryText1] = useState<string>("");
  const [storyText2, setStoryText2] = useState<string>("");
  const [storyText3, setStoryText3] = useState<string>("");
  const [storyText4, setStoryText4] = useState<string>("");
  const [storyText5, setStoryText5] = useState<string>("");
  const [storyCliffhanger, setStoryCliffhanger] = useState<string>("");
  const [storyOption1, setStoryOption1] = useState<string>("");
  const [storyOption2, setStoryOption2] = useState<string>("");
  const [storyOption3, setStoryOption3] = useState<string>("");
  const [optionChosen, setOptionChosen] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [chapterCreated, setChapterCreated] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showRatings, setShowRatings] = useState(false);
  const [showSupport, setShowSupport] = useState(false);
  const [storyFont, setStoryFont] = useState('');
  const [storyFontSize, setStoryFontSize] = useState('');
  const [storyFontWeight, setStoryFontWeight] = useState('');
  const [storyBackground, setStoryBackground] = useState('');
  const [ratingChapter, setRatingChapter] = useState(0);
  const [ratingFont, setRatingFont] = useState(0);

  const navigate = useNavigate();
  const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

  const characterImage = characters.find((c) => c.name === character)?.image;
  const characterName = characters.find((c) => c.name === character)?.name;

  const fonts = [
    { name: 'Nova Square', fontSize: '1.1rem', fontWeight: 'normal' },        { name: 'Caveat', fontSize: '1.4rem', fontWeight: 'normal' },          
    { name: 'Amatic SC', fontSize: '1.5rem', fontWeight: 'bold' },          { name: 'Permanent Marker', fontSize: '1.1rem', fontWeight: 'normal' },   
    { name: 'Indie Flower', fontSize: '1.3rem', fontWeight: 'normal' },       { name: 'Acme', fontSize: '1.3rem', fontWeight: 'normal' },
    { name: 'Orbitron', fontSize: '1.1rem', fontWeight: 'normal' },           { name: 'Lobster Two', fontSize: '1.3rem', fontWeight: 'normal' },     
    { name: 'Gloria Hallelujah', fontSize: '1.2rem', fontWeight: 'normal' },  { name: 'Architects Daughter', fontSize: '1.1rem', fontWeight: 'normal' },
    { name: 'Creepster', fontSize: '1.3rem', fontWeight: 'normal' },          { name: 'Gruppo', fontSize: '1.2rem', fontWeight: 'bold' },          
    { name: 'Poiret One', fontSize: '1.2rem', fontWeight: 'bold' },           { name: 'Special Elite', fontSize: '1.1rem', fontWeight: 'normal' },      
    { name: 'Annie Use Your Telescope', fontSize: '1.3rem', fontWeight: 'bold' }, { name: 'Love Ya Like A Sister', fontSize: '1.15rem', fontWeight: 'normal' }, 
    { name: 'Mountains of Christmas', fontSize: '1.35rem', fontWeight: 'normal' }, { name: 'Waiting for the Sunrise', fontSize: '1.3rem', fontWeight: 'bold' }, 
    { name: 'Dokdo', fontSize: '1.3rem', fontWeight: 'normal' },              { name: 'Trade Winds', fontSize: '1.2rem', fontWeight: 'normal' }, 
    { name: 'Gugi', fontSize: '1.15rem', fontWeight: 'normal' },              
    { name: 'Unkempt', fontSize: '1.3rem', fontWeight: 'normal' },            { name: 'Barrio', fontSize: '1.15rem', fontWeight: 'bold' },          
    { name: 'Jolly Lodger', fontSize: '1.5rem', fontWeight: 'normal' },       { name: 'Mystery Quest', fontSize: '1.3rem', fontWeight: 'normal' },      
    { name: 'Glass Antiqua', fontSize: '1.3rem', fontWeight: 'bold' },
    { name: 'Princess Sofia', fontSize: '1.35rem', fontWeight: 'normal' },    { name: 'Black And White Picture', fontSize: '1.3rem', fontWeight: 'normal' }, 
    { name: 'Butterfly Kids', fontSize: '1.5rem', fontWeight: 'bold' },       { name: 'Snowburst One', fontSize: '1.05rem', fontWeight: 'bold' },      
    { name: 'Fredericka the Great', fontSize: '1.2rem', fontWeight: 'bold' },
    { name: 'Freckle Face', fontSize: '1.3rem', fontWeight: 'normal' },       { name: 'Gochi Hand', fontSize: '1.3rem', fontWeight: 'normal' },      
    { name: 'Gorditas', fontSize: '1.15rem', fontWeight: 'normal' },           { name: 'Hanalei Fill', fontSize: '1.2rem', fontWeight: 'normal' },      
    { name: 'Irish Grover', fontSize: '1.15rem', fontWeight: 'normal' },       
    { name: 'Knewave', fontSize: '1.15rem', fontWeight: 'normal' },            { name: 'Kranky', fontSize: '1.2rem', fontWeight: 'bold' },          
    { name: 'Margarine', fontSize: '1.2rem', fontWeight: 'normal' },                   
    { name: 'Griffy', fontSize: '1.3rem', fontWeight: 'normal' },             { name: 'Piedra', fontSize: '1.3rem', fontWeight: 'normal' },
    { name: 'Rye', fontSize: '1.2rem', fontWeight: 'normal' },                
  ];
  /*
  const fonts = [
    'Nova Square', 'Caveat', 'Amatic SC', 'Satisfy', 'Permanent Marker', 'Indie Flower', 'Acme',
    'Orbitron', 'Lobster Two', 'Gloria Hallelujah', 'Kaushan Script', 'Architects Daughter', 'Creepster', 'Gruppo', 'Poiret One',
    'Special Elite', 'Rubik Moonrocks', 'Annie Use Your Telescope', 'Love Ya Like A Sister', 'Mountains of Christmas', 
    'Waiting for the Sunrise', 'Dokdo', 'Gugi', 'Kablammo', 'Unkempt', 'Barrio', 'Jolly Lodger',
    'Mystery Quest', 'Caesar Dressing', 'Glass Antiqua', 'Princess Sofia', 'Black And White Picture', 'Butterfly Kids',
    'Snowburst One', 'Bonbon', 'Fredericka the Great', 'Freckle Face', 'Gochi Hand', 'Gorditas', 
    'Hanalei Fill', 'Irish Grover', 'Jim Nightshade', 'Knewave', 'Kranky', 
    'Margarine', 'Monofett', 'Griffy', 'Piedra', 'Rye', 'Trade Winds', 
  ];
  */

  /*
    font filtering

    Nova Square +? a bit too big, keep for now
    Silkscreen ??? too big and hard to read, removed
    Caveat ++ keep
    Amatic SC ++ keep
    Satisfy ? a bit hard to read but fun, removed
    Permanent Marker +? a bit too big, keep for now
    Indie Flower ++ keep
    Acme +? just about ok, slightly too big, keep for now
    Orbitron +? nice font, slightly too big, keep for now
    Lobster Two ++ keep
    Gloria Hallelujah ? too big, think about removing
    Kaushan Script ? hard to read, removed
    Architects Daughter +? a bit too big, keep for now
    Creepster ++ keep
    Gruppo ? bit thin and too big, keep for now
    Poiret One +? needs to be bold
    Special Elite +? nice font maybe slightly too big, keep for now
    Monoton ?? too big and hard to read, removed
    Rubik Moonrocks ? too hard to read, removed
    Annie Use Your Telescope ++ keep
    Bungee Shade  ?? way too big and hard to read removed
    Love Ya Like A Sister  +? nice font maybe slightly too big, keep for now
    Mountains of Christmas', 
    Waiting for the Sunrise +? just about ok, a bit thin and hard to read, keep for now
    Dokdo  +? just about ok, keep for now
    Gugi +? nice font bit too big, keep for now
    Kablammo +? a bit hard to read but fun, too hard to read, removed
    Nosifer ?? nice font but way too big, removed, could add back later if smaller
    Unkempt  +? just about ok, nice but a little too big, keep for now
    Fontdiner Swanky  ? nice font but too big, removed can add back later
    Barrio +? a bit hard to read but fun, keep for now
    Jolly Lodger + a bit small but fun, keep for now
    Mystery Quest +? a bit hard to read but fun, keep for now
    Caesar Dressing +? a bit hard to read but fun, just too hard to read, removed
    Train One  ?? too big and hard to read, removed
    Glass Antiqua ++ keep
    Tilt Prism  ?? very hard to read, removed
    Princess Sofia + a little hard to read but fun, keep for now
    Black And White Picture + a little hard to read but fun, keep for now
    Butterfly Kids +? just about ok, fun but a bit too small, keep for now
    Snowburst One +? nice font but a bit too big, keep for now
    Bonbon +? a bit hard to read but fun, just too hard to read, removed
    Fredericka the Great +? a little hard to read but fun, keep for now
    Freckle Face ++ keep
    Faster One ?? too big and hard to read, removed
    Finger Paint ?? nice font but way too big, removed, could add back later if smaller
    Frijole ?? nice font but way too big, removed, could add back later if smaller
    Gochi Hand +? a little hard to read but fun, keep for now
    Gorditas +? a bit too big, keep for now
    Grand Hotel ??? hard to read, removed
    Hanalei Fill +? a bit too big, keep for now
    Hanalei ??? hard to read, removed
    Homemade Apple ??? too big and hard to read, removed
    Irish Grover +? just about ok, maybe slightly too big, keep for now
    Jacques Francois Shadow  ?? too big and hard to read, removed
    Jim Nightshade  +? is this readable enough?? too hard to read, removed
    Knewave +? just about ok, maybe slightly too big, keep for now
    Kranky +? just about ok, maybe slightly too big, keep for now
    Luckiest Guy ?? could work but way too big, remove now could bring back later if make it smaller
    Margarine ++ keep
    Monofett +? a little hard to read but fun, just too hard to read, removed
    Griffy + a little hard to read but fun, keep for now
    Piedra ++ keep
    Plaster ?? too big and hard to read, removed
    Rye ? too big but nice, keep for now
    Trade Winds ? too big but nice, keep for now
    UnifrakturCook ?? doesnt seem to work, removed
    UnifrakturMaguntia ?? too hard to read, removed
    Vast Shadow ?? way too big, remove for now, could bring back later if make it smaller
    Wallpoet ?? too big and hard to read, removed
  */

  const backgroundImages = [
    { image: `${process.env.PUBLIC_URL}/images/medieval_kingdom2.png`, character: "any", setting: "Medieval Kingdom", villain: "any", helper: "any" },
    { image: `${process.env.PUBLIC_URL}/images/medieval_kingdom4.png`, character: "any", setting: "Medieval Kingdom", villain: "any", helper: "any" },
    { image: `${process.env.PUBLIC_URL}/images/medieval_kingdom5.png`, character: "any", setting: "Medieval Kingdom", villain: "any", helper: "any" },
    { image: `${process.env.PUBLIC_URL}/images/medieval_kingdom6.png`, character: "any", setting: "Medieval Kingdom", villain: "any", helper: "any" },
    { image: `${process.env.PUBLIC_URL}/images/pirate_ships2.png`, character: "any", setting: "Pirate Ships", villain: "any", helper: "any" },
    { image: `${process.env.PUBLIC_URL}/images/pirate_ships4.png`, character: "any", setting: "Pirate Ships", villain: "any", helper: "any" },
    { image: `${process.env.PUBLIC_URL}/images/pirate_ships5.png`, character: "any", setting: "Pirate Ships", villain: "any", helper: "any" },
    { image: `${process.env.PUBLIC_URL}/images/pirate_ships6.png`, character: "any", setting: "Pirate Ships", villain: "any", helper: "any" },
    { image: `${process.env.PUBLIC_URL}/images/wild_west2.png`, character: "any", setting: "Wild West", villain: "any", helper: "any" },
    { image: `${process.env.PUBLIC_URL}/images/wild_west4.png`, character: "any", setting: "Wild West", villain: "any", helper: "any" },

    { image: `${process.env.PUBLIC_URL}/images/mia2.png`, character: "Mia", setting: "any", villain: "any", helper: "any" },
    { image: `${process.env.PUBLIC_URL}/images/mia3.png`, character: "Mia", setting: "any", villain: "any", helper: "any" },
    { image: `${process.env.PUBLIC_URL}/images/mia_medieval_kingdom1.png`, character: "Mia", setting: "Medieval Kingdom", villain: "any", helper: "any" },
    { image: `${process.env.PUBLIC_URL}/images/mia_wild_west1.png`, character: "Mia", setting: "Wild West", villain: "any", helper: "any" },
    { image: `${process.env.PUBLIC_URL}/images/mia_wild_west2.png`, character: "Mia", setting: "Wild West", villain: "any", helper: "any" },
    { image: `${process.env.PUBLIC_URL}/images/mia_pirate_ships1.png`, character: "Mia", setting: "Pirate Ships", villain: "any", helper: "any" },
    { image: `${process.env.PUBLIC_URL}/images/mia_pirate_ships2.png`, character: "Mia", setting: "Pirate Ships", villain: "any", helper: "any" },
    { image: `${process.env.PUBLIC_URL}/images/mia_pirate_ships3.png`, character: "Mia", setting: "Pirate Ships", villain: "any", helper: "any" },
    { image: `${process.env.PUBLIC_URL}/images/mia_pirate_ships4.png`, character: "Mia", setting: "Pirate Ships", villain: "any", helper: "any" },

    { image: `${process.env.PUBLIC_URL}/images/nat2.png`, character: "Nat", setting: "any", villain: "any", helper: "any" },
    { image: `${process.env.PUBLIC_URL}/images/nat_medieval_kingdom1.png`, character: "Nat", setting: "Medieval Kingdom", villain: "any", helper: "any" },
    { image: `${process.env.PUBLIC_URL}/images/nat_medieval_kingdom2.png`, character: "Nat", setting: "Medieval Kingdom", villain: "any", helper: "any" },
    { image: `${process.env.PUBLIC_URL}/images/nat_medieval_kingdom3.png`, character: "Nat", setting: "Medieval Kingdom", villain: "any", helper: "any" },
    { image: `${process.env.PUBLIC_URL}/images/nat_wild_west1.png`, character: "Nat", setting: "Wild West", villain: "any", helper: "any" },
    { image: `${process.env.PUBLIC_URL}/images/nat_wild_west2.png`, character: "Nat", setting: "Wild West", villain: "any", helper: "any" },
    { image: `${process.env.PUBLIC_URL}/images/nat_wild_west3.png`, character: "Nat", setting: "Wild West", villain: "any", helper: "any" },
    { image: `${process.env.PUBLIC_URL}/images/nat_wild_west4.png`, character: "Nat", setting: "Wild West", villain: "any", helper: "any" },
    { image: `${process.env.PUBLIC_URL}/images/nat_pirate_ships1.png`, character: "Nat", setting: "Pirate Ships", villain: "any", helper: "any" },
    { image: `${process.env.PUBLIC_URL}/images/nat_pirate_ships2.png`, character: "Nat", setting: "Pirate Ships", villain: "any", helper: "any" },
    { image: `${process.env.PUBLIC_URL}/images/nat_pirate_ships3.png`, character: "Nat", setting: "Pirate Ships", villain: "any", helper: "any" },

    { image: `${process.env.PUBLIC_URL}/images/alex2.png`, character: "Alex", setting: "any", villain: "any", helper: "any" },
    { image: `${process.env.PUBLIC_URL}/images/alex_wild_west1.png`, character: "Alex", setting: "Wild West", villain: "any", helper: "any" },
    { image: `${process.env.PUBLIC_URL}/images/alex_wild_west2.png`, character: "Alex", setting: "Wild West", villain: "any", helper: "any" },
    { image: `${process.env.PUBLIC_URL}/images/alex_wild_west3.png`, character: "Alex", setting: "Wild West", villain: "any", helper: "any" },
    { image: `${process.env.PUBLIC_URL}/images/alex_pirate_ships1.png`, character: "Alex", setting: "Pirate Ships", villain: "any", helper: "any" },
    { image: `${process.env.PUBLIC_URL}/images/alex_pirate_ships2.png`, character: "Alex", setting: "Pirate Ships", villain: "any", helper: "any" },
    { image: `${process.env.PUBLIC_URL}/images/alex_pirate_ships3.png`, character: "Alex", setting: "Pirate Ships", villain: "any", helper: "any" },
    { image: `${process.env.PUBLIC_URL}/images/alex_pirate_ships4.png`, character: "Alex", setting: "Pirate Ships", villain: "any", helper: "any" },
    { image: `${process.env.PUBLIC_URL}/images/alex_medieval_kingdom1.png`, character: "Alex", setting: "Medieval Kingdom", villain: "any", helper: "any" },
    { image: `${process.env.PUBLIC_URL}/images/alex_medieval_kingdom2.png`, character: "Alex", setting: "Medieval Kingdom", villain: "any", helper: "any" },
    { image: `${process.env.PUBLIC_URL}/images/alex_medieval_kingdom3.png`, character: "Alex", setting: "Medieval Kingdom", villain: "any", helper: "any" },

    { image: `${process.env.PUBLIC_URL}/images/isha_garden.png`, character: "Isha", setting: "any", villain: "any", helper: "any" },
    { image: `${process.env.PUBLIC_URL}/images/isha_street1.png`, character: "Isha", setting: "any", villain: "any", helper: "any" },
    { image: `${process.env.PUBLIC_URL}/images/isha_street2.png`, character: "Isha", setting: "any", villain: "any", helper: "any" },

    { image: `${process.env.PUBLIC_URL}/images/cameron2.png`, character: "any", setting: "any", villain: "any", helper: "Cameron the cat detective" },
    { image: `${process.env.PUBLIC_URL}/images/cameron3.png`, character: "any", setting: "any", villain: "any", helper: "Cameron the cat detective" },
    { image: `${process.env.PUBLIC_URL}/images/cameron4.png`, character: "any", setting: "any", villain: "any", helper: "Cameron the cat detective" },
    { image: `${process.env.PUBLIC_URL}/images/cameron5.png`, character: "any", setting: "any", villain: "any", helper: "Cameron the cat detective" },

    { image: `${process.env.PUBLIC_URL}/images/echo_forest.png`, character: "any", setting: "any", villain: "any", helper: "Echo the elf" },
    { image: `${process.env.PUBLIC_URL}/images/echo_river.png`, character: "any", setting: "any", villain: "any", helper: "Echo the elf" },
  ];

  const filteredBackgroundImages = backgroundImages.filter(image => 
      (image.character === character || image.character === 'any') &&
      (image.setting === setting || image.setting === 'any') &&
      (image.villain === villain || image.villain === 'any') &&
      (image.helper === helper || image.helper === 'any')
    );

  const getRandomBackground = () => {
    const randomIndex = Math.floor(Math.random() * filteredBackgroundImages.length);
    console.log('randomIndex: ', randomIndex)
    return filteredBackgroundImages[randomIndex].image;
  };


  const getRandomFont = () => {
    const randomIndex = Math.floor(Math.random() * fonts.length);
    console.log('randomIndex: ', randomIndex)
    return fonts[randomIndex];
  };

  const clearVariables = () => {
    setStoryChapterTitle("");
    setStoryText1("");
    setStoryText2("");
    setStoryText3("");
    setStoryText4("");
    setStoryText5("");
    setStoryCliffhanger("");
    setStoryOption1("");
    setStoryOption2("");
    setStoryOption3("");
    setPageIndex(0);
  }

  const createStory = async () => {
    // this is not used, replaced with the pregen first chapter
    console.log('creating story and first chapter');
    try {
      const storyResponse = await StoryAPI.postStory(character, helper, villain, setting, interests, storyLength, storyAge);
      console.log('Reply from BE: ', storyResponse);
      setStoryText1(storyResponse["section1_text"].replace(/\n/g, '<br />'));
      setStoryText2(storyResponse["section2_text"].replace(/\n/g, '<br />'));
      setStoryText3(storyResponse["section3_text"].replace(/\n/g, '<br />'));
      setStoryText4(storyResponse["section4_text"].replace(/\n/g, '<br />'));
      setStoryText5(storyResponse["section5_text"].replace(/\n/g, '<br />'));
      setStoryCliffhanger(storyResponse["cliffhanger_question"].replace(/\n/g, '<br />'));
      setStoryOption1(storyResponse["cliffhanger_option1"].replace(/\n/g, '<br />'));
      setStoryOption2(storyResponse["cliffhanger_option2"].replace(/\n/g, '<br />'));
      setStoryOption3(storyResponse["cliffhanger_option3"].replace(/\n/g, '<br />'));
      setTitle(storyResponse["story_title"]);
      setStoryChapterTitle(storyResponse["chapter_title"]);
      setStoryId(storyResponse["story_id"]);
      console.log('settting local storage uuid: ', storyResponse["uuid"]);
      localStorage.setItem('uuid', storyResponse["uuid"]);
    } catch (error) {
      console.error('Error fetching story: ', error);
      setStoryText1("Sorry, there was an error creating the story.");
    }
  }

  const createStoryPregen = async () => {
    console.log('creating story and first chapter using pregenerated story');
    try {
      
      const sidekickName = helper.split(" ")[0];
      const storySection1 = getStorySection1(character);
      const storySection2 = getStorySection2(character, sidekickName);
      const storySection3 = getStorySection3(character, sidekickName);
      const storySection4 = getStorySection4(character, sidekickName, setting);
      const storySection5 = getStorySection5(character, sidekickName, setting, villain);
      const storyCliffhangerOptions = getStorySectionCliffhanger(setting, villain);

      const cliffhangerQuestion = `What will ${character} and ${sidekickName} do now?`;
      const storyTitle = `${character} and ${sidekickName}'s ${setting} Adventure`;
      const chapterTitle = `${character} and ${sidekickName} start an Adventure`;
      const chapterSummary = `${character} meets ${helper} and they start their journey. They find themselves transported into a ${setting} adventure. They learn about the evil villain ${villain}.`;

      /*
      console.log('storySection1: ', storySection1);
      console.log('storySection2: ', storySection2);
      console.log('storySection3: ', storySection3);
      console.log('storySection4: ', storySection4);
      console.log('storySection5: ', storySection5);
      console.log('cliffhangerQuestion: ', cliffhangerQuestion);
      console.log('storyCliffhangerOptions: ', storyCliffhangerOptions);
      console.log('chapterSummary: ', chapterSummary);
      */

      setStoryText1(storySection1.replace(/\n/g, '<br />'));
      setStoryText2(storySection2.replace(/\n/g, '<br />'));
      setStoryText3(storySection3.replace(/\n/g, '<br />'));
      setStoryText4(storySection4.replace(/\n/g, '<br />'));
      setStoryText5(storySection5.replace(/\n/g, '<br />'));
      setStoryCliffhanger(cliffhangerQuestion.replace(/\n/g, '<br />'));
      setStoryOption1(storyCliffhangerOptions[0].replace(/\n/g, '<br />'));
      setStoryOption2(storyCliffhangerOptions[1].replace(/\n/g, '<br />'));
      setStoryOption3(storyCliffhangerOptions[2].replace(/\n/g, '<br />'));
      setTitle(storyTitle);
      setStoryChapterTitle(chapterTitle);

      // need to send the information to the BE to store and get back the story_id and the uuid
      console.log('saving story and chapter 1');
      const storyResponse = await StoryAPI.postStoryPregen(
        character, helper, villain, setting, interests, storyLength, storyAge,
        storySection1, storySection2, storySection3, storySection4, storySection5, 
        cliffhangerQuestion, storyCliffhangerOptions[0], storyCliffhangerOptions[1], storyCliffhangerOptions[2], 
        storyTitle, chapterTitle, chapterSummary, storyFont);
      console.log('save complete');
      setStoryId(storyResponse["story_id"]);
      console.log('settting local storage uuid: ', storyResponse["uuid"]);
      localStorage.setItem('uuid', storyResponse["uuid"]);
    } catch (error) {
      console.error('Error fetching story: ', error);
      setStoryText1("Sorry, there was an error creating the story.");
    }
  }

  const createChapter = async () => {
    console.log('creating new chapter, pausing');
    try {
      const chapterResponse = await StoryAPI.postChapter(storyId, storyFont);
      console.log('Reply from BE: ', chapterResponse);
      setStoryText1(chapterResponse["section1_text"].replace(/\n/g, '<br />'));
      setStoryText2(chapterResponse["section2_text"].replace(/\n/g, '<br />'));
      setStoryText3(chapterResponse["section3_text"].replace(/\n/g, '<br />'));
      setStoryText4(chapterResponse["section4_text"].replace(/\n/g, '<br />'));
      setStoryText5(chapterResponse["section5_text"].replace(/\n/g, '<br />'));
      setStoryCliffhanger(chapterResponse["cliffhanger_question"].replace(/\n/g, '<br />'));
      setStoryOption1(chapterResponse["cliffhanger_option1"].replace(/\n/g, '<br />'));
      setStoryOption2(chapterResponse["cliffhanger_option2"].replace(/\n/g, '<br />'));
      setStoryOption3(chapterResponse["cliffhanger_option3"].replace(/\n/g, '<br />'));
      setTitle(chapterResponse["story_title"]);
      setStoryChapterTitle(chapterResponse["chapter_title"]);
      setChapterCreated(true);
    } catch (error) {
      console.error('Error fetching story: ', error);
      setStoryText1("Sorry, there was an error creating the story. Please wait the story will reload");
      await delay(5000); 
      navigate('/');
    }
  }

  const loadStoryPage = async () => {
    console.log('loading story page');
    const randomFont = getRandomFont();
    setStoryFontSize(randomFont.fontSize);
    setStoryFontWeight(randomFont.fontWeight);
    setStoryFont(randomFont.name);
    console.log('Selected font:', randomFont);
    console.log('storyFont:', storyFont);
    console.log('storyFontSize:', storyFontSize);
    const randomBackground = getRandomBackground();
    setStoryBackground(randomBackground);
    console.log('Selected background image:', randomBackground);
    console.log('characterImage=', characterImage, ', characterName=', characterName);
    console.log('character=', character);
    console.log('chapter: ', chapter, ', chapterCreated: ', chapterCreated, ', character: ', character);
    if (!chapterCreated) {
      if (chapter === 1) {
        console.log('creating story and first chapter');
        createStoryPregen();
        // await createStory();
      } else {
        console.log('creating new chapter');
        await createChapter();
      }
    } else {
      // load the story, this happens when user comes back to already created story
      console.log('loading already created chapter');
      try {
        const storyResponse = await StoryAPI.getChapter(storyId, chapter);
        console.log('Reply from BE: ', storyResponse);
        setStoryText1(storyResponse["section1_text"].replace(/\n/g, '<br />'));
        setStoryText2(storyResponse["section2_text"].replace(/\n/g, '<br />'));
        setStoryText3(storyResponse["section3_text"].replace(/\n/g, '<br />'));
        setStoryText4(storyResponse["section4_text"].replace(/\n/g, '<br />'));
        setStoryText5(storyResponse["section5_text"].replace(/\n/g, '<br />'));
        setStoryCliffhanger(storyResponse["cliffhanger_question"].replace(/\n/g, '<br />'));
        setStoryOption1(storyResponse["cliffhanger_option1"].replace(/\n/g, '<br />'));
        setStoryOption2(storyResponse["cliffhanger_option2"].replace(/\n/g, '<br />'));
        setStoryOption3(storyResponse["cliffhanger_option3"].replace(/\n/g, '<br />'));
        setStoryChapterTitle(storyResponse["chapter_title"].replace(/\n/g, '<br />'));
      } catch (error) {
        console.error('Error fetching story: ', error);
        setStoryText1("Sorry, there was an error loading the story.");

      }
    }
  }

  useEffect(() => {
    console.log('useEffect called, chapter: ', chapter, ', chapterCreated: ', chapterCreated);
    clearVariables();
    if (chapter === 0) {
      setChapterCreated(false);
      setChapter(1);  // bit convoluted but if chapter = 0 then will reload useEffect with chapter 1 and chapter not created
    } else {
      loadStoryPage();
    }
  }, [chapter]);

  const renderText = (text: string): string => {
    /*
    const sentences = text.split(".");
  
    return sentences.map(sentence => {
      // Trim each sentence and ensure it's not just an empty paragraph
      const trimmedSentence = sentence.trim();
      return trimmedSentence ? `<p>${trimmedSentence}</p>` : '';
    }).join('');
    */
    //return `<p>${text}</p>`;
    return text;
  };
  
  

  const pages = 6;
  var textToDisplay: string | null = '';
  switch(pageIndex) {
    case 0:
      textToDisplay = renderText(storyText1)
      break;
    case 1:
      textToDisplay = renderText(storyText2) 
      break;
    case 2:
      textToDisplay = renderText(storyText3) 
      break;
    case 3:
      textToDisplay = renderText(storyText4) 
      break;
    case 4:
      textToDisplay = renderText(storyText5) 
      break;
    case 5:
      textToDisplay = "should not reach here"
      break;
    default:
      // code block
  }

  const handleOptionClick = (option: number) => {
    console.log('setting option clicked: ', option);
    setOptionChosen(option);
    setRatingChapter(0);
    setRatingFont(0);
    setShowRatings(true);
  }

  const handleConfirmOptionClick = async () => {
    console.log('option clicked: ', optionChosen, ', ratingChapter: ', ratingChapter, ', ratingFont: ', ratingFont, ', storyFont: ', storyFont);
    await StoryAPI.putOption(storyId, chapter, optionChosen, ratingChapter, ratingFont, storyFont);
    console.log('before: chapter: ', chapter);
    setChapter(chapter + 1);
    setChapterCreated(false);
    setShowRatings(false);
  };

  const renderCliffhangerOptions = () => {
    return (
      <div className="story-box story-options row col-10" style={{ fontFamily: storyFont, fontSize: storyFontSize, fontWeight: storyFontWeight }}>
        <div className='text-center'>{storyCliffhanger}</div>
        <button className="button-paper" style={{ fontFamily: storyFont, fontSize: storyFontSize, fontWeight: storyFontWeight }} onClick={() => handleOptionClick(1)} role="button">
          {storyOption1}
        </button>
        <button className="button-paper" style={{ fontFamily: storyFont, fontSize: storyFontSize, fontWeight: storyFontWeight }} onClick={() => handleOptionClick(2)} role="button">
          {storyOption2}
        </button>
        <button className="button-paper" style={{ fontFamily: storyFont, fontSize: storyFontSize, fontWeight: storyFontWeight }} onClick={() => handleOptionClick(3)} role="button">
          {storyOption3}
        </button>
      </div>
    );
  };

  const renderFinishScreen = () => {
    return (
      <div className="story-box story-options row col-10" style={{ fontFamily: storyFont, fontSize: storyFontSize, fontWeight: storyFontWeight }}>
        <div className='text-center'>The End!</div>
        <div className='text-center'>Thank you for participating in this storybook adventure, please leave feedback or support the project.</div>
        <button className="btn btn-light" style={{ fontFamily: storyFont, fontSize: storyFontSize, fontWeight: storyFontWeight }} onClick={handleFeedback}>
          Feedback page
        </button>
        <button className="btn btn-light" style={{ fontFamily: storyFont, fontSize: storyFontSize, fontWeight: storyFontWeight }} onClick={handleSupport}>
          Support the project
        </button>
        <button className="btn btn-light" style={{ fontFamily: storyFont, fontSize: storyFontSize, fontWeight: storyFontWeight }} onClick={handleNewStory}>
          Create a new story
        </button>
      </div>
    );
  };

  const renderOptionsScreen = () => {
    if (storyCliffhanger === "") {
      return renderFinishScreen();
    } else {
      return renderCliffhangerOptions();
    }
  };

  

  const handleNewStory = () => {
    setShowConfirmation(true);
  };

  const handleSupport = () => {
    setShowSupport(true);
  };

  const navigatePatreon = () => {
    window.location.href = 'https://www.patreon.com/StorybookAI';
    setShowSupport(false);
  };

  const handleFeedback = () => {
    navigate('/feedback');
  };

  const handleConfirmNewStory = () => {
    setChapter(-1);
    setTitle("");
    interests.forEach((interest) => removeInterest(interest));
    navigate('/');
    setShowConfirmation(false);
  };


  const handleClose = () => setShowConfirmation(false);

  const handleCloseSupport = () => setShowSupport(false);

  const handleCloseRatings = () => setShowRatings(false);

  const ratingFaces = ["😡", "🙁", "😐", "🙂", "😍"];



  return (
    <div className="dimension-container">
      <div className="container px-0"
          style={{
            backgroundImage: `url(${storyBackground})`,
            backgroundSize: 'cover', 
            backgroundRepeat: 'no-repeat', 
            backgroundPosition: 'center'
          }}
        >

        <i className="bi bi-trash3 new-story-button2" onClick={handleNewStory}></i>
        <i className="bi bi-heart support-button" onClick={handleSupport}></i>
        <i className={`bi ${isFullScreen ? 'bi-fullscreen-exit' : 'bi-fullscreen'} fullscreen-button-story`} onClick={toggleFullScreen}></i>
        <i className="bi bi-envelope feedback-button" onClick={handleFeedback}></i>

        <div className='story-titles'>
          <div className="text-80 text-center story-title title-font-bold">{title}</div>
          <div className="text-center chapter-title title-font-bold">{storyChapterTitle ? "Chapter " + chapter + ": " + storyChapterTitle : ""}</div>
        </div>

        {!storyText1 && (
          <div className="story-box story-loading row col-10" style={{ fontFamily: storyFont, fontSize: storyFontSize, fontWeight: storyFontWeight }}>
            <div className='text-center'>Creating story, please wait.</div>
            <div className="loading-animation-story">
              <RotatingTriangles
                visible={true}
                height="80"
                width="80"
                ariaLabel="rotating-triangels-loading"
                wrapperStyle={{}}
                wrapperClass="rotating-triangels-wrapper"
              />
            </div>
        </div>
        )}

        

        {storyText1 && pages > 1 && (
          <div className="story-area">
            <div className="nav-button left" onClick={() => pageIndex > 0 && setPageIndex(pageIndex - 1)} >
              <i className="bi bi-caret-left-fill"></i>
            </div>

            {storyText1 && pageIndex <= 4 && (
              <div className="story-box story-text row col-10" style={{ fontFamily: storyFont, fontSize: storyFontSize, fontWeight: storyFontWeight }} 
              dangerouslySetInnerHTML={{ __html: textToDisplay }} />
            )}

            {storyText1 && pageIndex === 5 && renderOptionsScreen()}
        

            <div className="nav-button right" onClick={() => pageIndex < pages - 1 && setPageIndex(pageIndex + 1)} >
              <i className="bi bi-caret-right-fill"></i>
            </div>
          </div>
        )}

        <div className="pagination">
          <div  className={`dot ${pageIndex === 0 ? 'active' : ''}`} />
          <div  className={`dot ${pageIndex === 1 ? 'active' : ''}`} />
          <div  className={`dot ${pageIndex === 2 ? 'active' : ''}`} />
          <div  className={`dot ${pageIndex === 3 ? 'active' : ''}`} />
          <div  className={`dot ${pageIndex === 4 ? 'active' : ''}`} />
          <div  className={`dot ${pageIndex === 5 ? 'active' : ''}`} />
        </div>

        <div className="story-bottom-section"></div>

        <Modal show={showConfirmation} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to start a new story? You will lose your current story.</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleConfirmNewStory}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showSupport} onHide={handleCloseSupport}>
          <Modal.Header closeButton>
            <Modal.Title>Patreon page</Modal.Title>
          </Modal.Header>
          <Modal.Body>Please support this project to allow us to improve it and offer more. This will take you away from this site to the Storybook AI patreon page.</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseSupport}>
              Cancel
            </Button>
            <Button variant="primary" onClick={navigatePatreon}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showRatings} onHide={handleCloseRatings}>
          <Modal.Header closeButton>
            <Modal.Title>Feedback</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="text-center feedback-title" style={{ fontFamily: storyFont }}>Chapter rating</div>
            
            <div className="rating-section2">
              {[1,2,3,4,5].map((value) => (
                <button 
                    type="button"
                    key={value} 
                    className={`rating-button btn ${ratingChapter === value ? 'btn-primary' : 'btn-outline-primary'}`} 
                    onClick={() => setRatingChapter(value)}
                    data-emoticon={ratingFaces[value - 1]}
                  >
                </button>
              ))}
            </div>

            <div className="text-center feedback-title" style={{ fontFamily: storyFont }}>Font rating</div>
            <div className="rating-section2">
              {[1,2,3,4,5].map((value) => (
                <button 
                    type="button"
                    key={value} 
                    className={`rating-button btn ${ratingFont === value ? 'btn-primary' : 'btn-outline-primary'}`} 
                    onClick={() => setRatingFont(value)}
                    data-emoticon={ratingFaces[value - 1]}
                  >
                </button>
              ))}
            </div>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleConfirmOptionClick}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
        
      </div>
    </div>
  );
}

export default Story;