import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useAppContext } from '../../AppContext';
import { RotatingTriangles } from 'react-loader-spinner';
import { StoryAPI } from '../../api/StoryAPI'; 
import './StartScreen.css'; 
import './StoryApp.css'; 

function StartingScreen() {
  const { setStoryId, setStoryLength, setChapter, setTitle, selectCharacter, selectSetting, selectVillain, selectHelper, chapter } = useAppContext();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

  const handleStoryLength = (length: string) => {
    console.log(`Selected story length: ${length}`);
    setStoryLength(length);
    navigate('/character');
  };

  useEffect(() => {
    async function fetchStory() {
      try {
        if (chapter === -1) {
          console.log('navigation from story, creating new story');
          setChapter(0);
          setLoading(false);
        } else {
          await delay(2000); 
          const uuid = localStorage.getItem('uuid');
          if (uuid) {
            console.log('stored uuid found: ', uuid);
            const response = await StoryAPI.getStory(uuid);
            
            if (response["exists"]) {
              setChapter(response["chapter"]);
              setTitle(response["title"]);
              setStoryId(response["story_id"]);
              selectCharacter(response["character"]);
              selectSetting(response["setting"]);
              selectVillain(response["villain"]);
              selectHelper(response["helper"]);
              navigate('/story');
              //navigate('/word-story');
            } else {
              console.log('cannot find story with stored uuid');
              localStorage.removeItem('storyId');
              setChapter(0);
              setLoading(false);
            }
          } else { 
            console.log('No stored uuid found');
            setChapter(0);
            setLoading(false);
          }
        }
      } catch (error) {
        console.error('Error fetching story:', error);
        setLoading(false);
      }
    }
    fetchStory();
  }, []);

  return (
    <div className="dimension-container">
      <div className="selection-container px-0"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/start_screen5.png)`,
          backgroundSize: 'cover', 
          backgroundRepeat: 'no-repeat', 
          backgroundPosition: 'center'
        }}>
        <div className="mt-5"/>
        <h1 className="storybook-title title-font">Storybook</h1>
        <h1 className="storybook-title title-font">AI</h1>
        {loading ? (
          <div className="loading-animation-start">
            <RotatingTriangles
              visible={true}
              height="80"
              width="80"
              ariaLabel="rotating-triangles-loading"
              wrapperStyle={{}}
              wrapperClass="rotating-triangles-wrapper"
            />
          </div>
        ) : ( 
          <div className="story-length-options">
            <h2 className="title-font" style={{ color: 'white' }}>Pick story length</h2>
            <button onClick={() => handleStoryLength('short')} className="button-paper" role="button">Short story (2 min)</button>
            <button onClick={() => handleStoryLength('medium')} className="button-paper" role="button">Medium story (5 min)</button>
            <button onClick={() => handleStoryLength('long')} className="button-paper" role="button">Long story (15 min)</button>
            <button onClick={() => handleStoryLength('endless')} className="button-paper" role="button">Endless story (∞ min)</button>
          </div>
        )}
      </div>
    </div>
  );
}

export default StartingScreen;
