import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './BingoGame.css';

// 5, 6, 4, 2, 1, 3


const items: Record<string, string[]> = {
  leigh: [
    'Sunset', 'Lizard or Frog', 'Cemetary', 'Ice Cream',
    'Pickup Truck', 'Fliss Says "Mummy Milk"', 'Roller Skater', 'Person Sneezes',
    'Spork', 'Pavlova', 'Art Gallery', 'Bike With A Trailer',
    'Roller Blader', 'Text In Foreign Language', 'Fridge Magnet', 'Coconut Drink',
  ],
  lauren: [
    'Outsider Man Drinking Wine', 'River', 'Street Art', 'Unisex Toilet',
    'Walking Trail', 'Farm', 'Fliss Says "Maybe"', 'Red Backpack',
    'Bridge', 'Croissant', 'Art Gallery', 'Physical Map',
    'Red Flower', 'Bicycle', 'Outsiders Binoculars', 'Wombat or Koala',
  ],
  nathan: [
    'Sunrise', 'Coffee Shop', 'Bookstore', 'Museum',
    'Taster Float', 'Yahtzee', 'Painting Of A Landscape', 'Kombucha',
    'Kangaroo', 'Statue', 'Pottery', 'Fliss Says "Crumpet"',
    'Visitor Information Centre', 'Bicycle', 'The Number 55', 'Outsider Littering',
  ],
  jenny: [
    'Clock Without Numbers', 'Ghost Tour Sign', 'Outsider Saying F Word', 'Craft Shop',
    'Gym', 'Possum', 'Car Honking', 'Cheesecake',
    'Hostel', 'Open Misere', 'Tandem Bike', 'Brownie',
    'Post Office', 'Outsider Woman Drinking A Beer', 'Fliss says "Koko"', 'Butterfly',
  ],
  ashlyn: [
    'Waterfall', 'Sports Pitch', 'Sports Drink', 'Somebody Trips Over',
    'Record Store', 'Electric Bike or Scooter', 'People Picnicing', 'Toyota Car',
    'Swimming Pool', '16+ In Cribbage', 'Bakery', 'Ghost Tour Advert',
    'Lamington Cake', 'Horse', 'Vineyard', 'Fliss Sings ABC Song',
  ],
  claire: [
    'Soccer Ball', 'Vanilla Slice', 'Jail Or Gaol', 'Motorbike',
    'Swimming Pool', 'Lake', 'Deal Breaker Happens', 'Parking Sign',
    'Caravan', 'Cat', 'Playground', 'Cemetary',
    'Bookstore', 'Fliss Says "Almond Butter"', 'Yoga Class Advert', 'Outsider Drinking Cider',
  ],
};

const otherItems: string[] = [
  'Bakery', 'Honey Discovery Centre', 'Jail/Gaol', 'Old Courthouse',
  'Ned Kelly Vault', 'Lake', 'Burke Museum', 'Historic Precinct',
  'Gorge Scenic Drive', 'Sweet Co.', 'Wine Estates', 'Historic Park', 'Chinese Gardens',
  'Mayday Hills Lunatic Asylum', 'Art Gallery',
  'Billsons Brewery', 'Powder Magazine',
  'Cemetery', 'Golden Horseshoes Monument', 'Tanswells Commercial Hotel',
  'Visitor Information Centre', 'Mountain Bike Park', 'Provender',
  'Berries', 'Bridge Road Brewers', 'Historic Post Office',
  'Granite Walking Trails', 'Public Gardens', 'Gorge Walk', 'Rail Trail',
  'Lake Kerferd', 'Alpacas', 'Baarmutha Park', 'Farmers Market',
  'Emporium', 'Queen Victoria Park', 'The Stanley Pub', 'Gold Tours',
  'Heritage Walk', 'Baarmutha Winery',
  'Anzac Memorial', 'Trestle Bridge', 'Fine Art Gallery',
  'Ovens River', 'Carriage Rides', 'Historic Churches',
  'Ghost Tours', 'Indigo Vineyard',
  'Granite Quarry', 'Orchards',
  'Cider',
  'Cultural Precinct',
  'Craft Shops',
  'Antiques and Collectibles',
  'Chocolates',
  'Honey Experience',
  'Chain Gang Mining Display',
  'Heritage Festivals',
  'Hikes and Trails',
  'Pottery',
  'Local Artisans',
  'Stone Bridge',
  'Scenic Lookout',
  'Autumn Leaves',
  'Historic Pubs',
  'Bushwalking',
  'Cycling Routes',
  'Native Wildlife',
  'Picnic Spots',
  'Wine Tasting Sign',
  'Gourmet Food Sign',
  'Historic Street Lamps',
  'Historic Signage',
  'War Memorial',
  'Old Police Stables',
  'Vineyards',
  'Gardens and Parks',
  'Local Market',
  'Street Art',
  'Historic Photographs',
  'Eucalyptus Trees',
  'Native Flora',
  'Bird Watching',
  'Wineries Tour',
  'Horse Riding',
  'Rock Pools',
  'Camping Grounds',
  'Fishing Spots',
  'Artisans Festival',
  'Music Events',
  'Historic Mining Sites',
  'Stone Buildings',
  'Heritage Trees',
  'Walking Tours',
  'Old Telegraph Station',
  'Local History Talks',
  'Sourdough Bakery',
  'Heritage Street Signs',
  'Woolshed Falls'
];

  
interface RouteParams extends Record<string, string | undefined> {
  person?: string;
}


function BingoGame() {
  const { person } = useParams<RouteParams>();
  const [gridItems, setGridItems] = useState<string[]>([]);
  const [crossedItems, setCrossedItems] = useState<Set<string>>(new Set());
  const [hasLine, setHasLine] = useState<boolean>(false); // New state variable

  // Define a unique key for localStorage based on the person
  const localStorageKey = 'holiday-bingo-crossed';

  useEffect(() => {
    let selectedItems: string[] = [];

    if (person && items.hasOwnProperty(person.toLowerCase())) {
      selectedItems = items[person.toLowerCase()];
    } else {
      const allItemsSet = new Set<string>();

      // Add all items from all people
      Object.values(items).forEach((personItems) => {
        personItems.forEach((item) => allItemsSet.add(item));
      });

      // Add otherItems
      otherItems.forEach((item) => allItemsSet.add(item));

      const allItemsArray = Array.from(allItemsSet);

      // Shuffle the array and select the first 16 items
      selectedItems = shuffleArray(allItemsArray).slice(0, 16);
    }

    setGridItems(selectedItems);

    // Load crossedItems from localStorage
    const storedCrossed = localStorage.getItem(localStorageKey);
    if (storedCrossed) {
      try {
        const parsedCrossed: string[] = JSON.parse(storedCrossed);
        setCrossedItems(new Set(parsedCrossed));
        // Check if there's already a line
        const existingLine = checkLines(selectedItems, new Set(parsedCrossed));
        setHasLine(existingLine);
      } catch (error) {
        console.error('Error parsing crossed items from localStorage:', error);
      }
    } else {
      setCrossedItems(new Set());
      setHasLine(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [person]); // Run when 'person' changes

  const toggleMark = (item: string) => {
    const newCrossed = new Set(crossedItems);
    if (newCrossed.has(item)) {
      newCrossed.delete(item);
    } else {
      newCrossed.add(item);
    }
    setCrossedItems(newCrossed);
    saveCrossedToLocalStorage(newCrossed);
    checkForWin(newCrossed);
  };


  const getLines = () => {
    return [
      // Rows
      [0, 1, 2, 3],
      [4, 5, 6, 7],
      [8, 9, 10, 11],
      [12, 13, 14, 15],
      // Columns
      [0, 4, 8, 12],
      [1, 5, 9, 13],
      [2, 6, 10, 14],
      [3, 7, 11, 15],
      // Diagonals
      [0, 5, 10, 15],
      [3, 6, 9, 12],
    ];
  };

  const checkForWin = (newCrossed: Set<string>) => {
    const lines = getLines();

    const hasCompletedLine = lines.some((line) =>
      line.every((index) => newCrossed.has(gridItems[index]))
    );

    const hasFullGrid = gridItems.every((item) => newCrossed.has(item));

    if (hasCompletedLine && !hasLine) {
      alert('Congrats you got a line!');
      setHasLine(true);
    } else if (!hasCompletedLine && hasLine) {
      // User had a line but now doesn't
      setHasLine(false);
    }

    if (hasFullGrid) {
      alert('Congrats you filled the whole square!');
    }
  };

  const checkLines = (items: string[], crossed: Set<string>): boolean => {
    const lines = getLines();
    return lines.some((line) =>
      line.every((index) => crossed.has(items[index]))
    );
  };


  const saveCrossedToLocalStorage = (crossedSet: Set<string>) => {
    const crossedArray = Array.from(crossedSet);
    localStorage.setItem(localStorageKey, JSON.stringify(crossedArray));
  };

  // Utility function to shuffle an array
  function shuffleArray(array: string[]) {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  }


  return (
    <div className="bingo-container">
      <div className='empty-space'></div>
      <div className="bingo-grid">
        {gridItems.map((item, index) => (
          <div
            key={index}
            className={`bingo-cell ${crossedItems.has(item) ? 'marked' : ''}`}
            onClick={() => toggleMark(item)}
          >
            <span className="bingo-item">{item}</span>
            {crossedItems.has(item) && <div className="cross">X</div>}
          </div>
        ))}
      </div>
    </div>
  );
}

export default BingoGame;
