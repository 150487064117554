export const getStorySection1 = (character: string): string => {
  const stories: { [key: string]: string[] } = {
    Nat: [
      "In a sunny corner of Greenwood Town lived Nat, a child with wild brown hair and a sparkle of adventure in their eyes, always ready for new discoveries.",
      "Nat, with bouncy brown hair and a spirit of excitement, lived in a cozy house on Maple Lane, dreaming of grand adventures every sunny morning.",
      "Among the hills of Willowdale, Nat's laughter rang. A curious child with chestnut hair, they found joy and wonder in the simplest of things.",
      "Nat, a bundle of energy with curls like autumn leaves, lived in the heart of Summerfield, where every day promised a new adventure.",
      "In the vibrant village of Riverbend, young Nat, a dreamer with lively brown curls, embarked on daily quests filled with magic, mystery, and endless fun.",
      "Nat, with a heart full of dreams and wild brown hair tousled by the wind, gazed out towards the horizon, yearning for adventures unknown.",
      "In the early morning light, Nat's laughter echoed through the streets. Nat's excitement for adventure as boundless as the sky.",
      "With a untamed spirit Nat spent long days imagining fantastical adventures, each more daring than the last.",
      "Nat raced through the fields, eyes sparkling with mischief and wonder, every step a dance with the wind.",
      "Under the vast canopy of stars, Nat dreamt of distant lands and thrilling quests, Nat's heart beating to the rhythm of adventure.",
      "In a small, lively corner of the town, Nat's imagination roamed free, turning every nook into a stage for their endless adventures.",
      "Every day, Nat explored the world with wide-eyed wonder, Nat's untamed brown hair a testament to their boundless energy and curiosity.",
      "With a heart brimming with dreams, Nat often wandered off into their own world, where every moment was an adventure waiting to happen.",
      "In the heart of the bustling town, Nat stood out with their vivacious spirit, ready to turn each day into an extraordinary story.",
      "As the sun rose, Nat's laughter filled the air, Nat's imagination turning the ordinary streets into realms of endless possibilities.",
    ],
    Mia: [
      "In the quaint village of Willow Creek, Mia spent her days playing hide and seek among the tall oaks. Her laughter was as bright as her frizzy hair, often heard echoing through the streets.",
      "Mia loved nothing more than exploring the fields around her home, her dress swirling in the wind. She would often return with pockets full of wildflowers and a heart full of stories.",
      "Every morning, Mia greeted the sun from her balcony, her eyes sparkling with the promise of new adventures. She often daydreamed about far-off lands, twirling in her dress as she imagined her journeys.",
      "Mia, with her keen sense of curiosity, could often be found at the town library, poring over books of ancient legends and tales, dreaming of her own adventures that lay ahead.",
      "In a small, lively corner of Emerald Town, Mia was known for her quick wit and kind heart. She spent her evenings under the stars, sharing tales and dreams with her friends.",
      "Mia, with her frizzy hair dancing in the breeze, would spend her days exploring the hidden corners of her neighborhood, brimming with curiosity and joy.",
      "In the heart of the city, Mia's radiant smile and adventurous spirit were as unmistakable as the bright dress she loved to wear.",
      "Every morning, Mia greeted the day with a twirl of her dress and a dream in her heart, ready for a new adventure.",
      "Mia's laughter was a familiar melody in the bustling streets, her presence like a burst of sunshine on a cloudy day.",
      "With a skip in her step and dreams in her eyes, Mia transformed every pavement into a path leading to magical worlds.",
      "Mia's imagination turned her backyard into a realm of endless possibilities, her dress swirling as she danced amidst her make-believe adventures.",
      "Under the old oak tree, Mia often sat, her dress spread around her like a flower in bloom, lost in her daydreams.",
      "In the quiet of her room, Mia would weave stories of far-off places, her dress a cape of bravery in her solitary play.",
      "Mia loved to chase the butterflies in the garden, her laughter echoing like a sweet melody, her dress mirroring the colors around her.",
      "At the local park, Mia was the unspoken queen of the playground, her vibrant dress a blur as she played with boundless energy.",
    ],
    Alex: [
      "Alex, with a cap always perched atop his long hair, was the undisputed skateboarding champion of Pineville. His days were filled with thrilling rides and cool tricks at the local skatepark.",
      "In the bustling suburb of Oakwood, Alex zipped through the streets on his skateboard, his long hair flowing behind. He was known for his daring stunts and easygoing smile.",
      "Every weekend, Alex and his friends gathered at the old warehouse, now a vibrant skatepark. With his cap turned backwards and skateboard in hand, he was ready for adventure.",
      "Under the bright city lights, Alex's skater style was unmistakable. His long hair and cap became a symbol of freedom for the local skate crew.",
      "Alex's dream was to compete in the national skateboarding championship. He practiced every day, perfecting his moves, his cap a constant companion on these adventures.",
      "Alex, with a skateboard underfoot, zipped through the neighborhood, his cap turned backward, a symbol of his free-spirited nature.",
      "In the local skate park, Alex's silhouette against the setting sun was a dance of fluid motion, his cap a constant companion.",
      "With his headphones on and cap pulled low, Alex found solace in the rhythm of his skateboard on the winding paths.",
      "Alex's laughter filled the air as he performed tricks on his skateboard, his cap, like a crown, never leaving his head.",
      "Every evening, as shadows lengthened, Alex's cap became a part of the neighborhood's backdrop, a sign of his adventurous spirit.",
      "Under the golden afternoon sun, Alex cruised along the sidewalk, his skateboard a flash of color, cap tilted at a jaunty angle.",
      "At the heart of the bustling town square, Alex's skateboard echoed against the cobblestones, his cap a symbol of his youthful exuberance.",
      "Alex's world was a blur of motion, his skateboard his steed, cap a trusty helm, as he navigated the urban maze.",
      "In the quiet of the early dawn, Alex practiced his kickflips, the only sounds his skateboard's clatter and the rustle of his cap.",
      "Each evening, as the neighborhood quieted, Alex's skateboard became a streak of light, his cap bobbing like a ship's sail in the breeze.",
    ],
    Isha: [
      "In the heart of a bustling market, Isha twirled, her orange dress catching the sunlight. Her laughter was a melody, her long dark hair flowing with each spin.",
      "Under the shade of an old banyan tree, Isha sat, her traditional dress a bright contrast to the green leaves. She wove stories as intricate as the patterns on her dress.",
      "Isha's eyes sparkled with mischief as she darted through the narrow alleyways, her traditional orange dress blending with the vibrant colors of the town.",
      "In a quiet corner of the library, Isha was lost in a world of books. Her long, dark hair fell over her traditional dress, a vivid splash of orange among the shelves.",
      "Isha's laughter echoed in the open fields as she chased the wind, her dress swirling around her. The setting sun cast a warm glow on her tanned skin and dark hair.",
      "Isha, clad in her radiant orange dress, strolled along the riverbank, her dark hair flowing in the breeze, reflecting on tales of ancient legends and mystic lands.",
      "In the heart of the bustling marketplace, Isha, adorned in her traditional dress, shared stories and laughter, her spirit as vibrant as the colors surrounding her.",
      "Under the old banyan tree, Isha sat, her orange dress merging with the sunset. She was lost in a book of old fables, her imagination alight with adventure.",
      "Isha twirled gracefully in the village square, her long hair fanning out like the wings of a bird, her dress a swirl of vivid orange and tradition.",
      "In the quiet of the ancient library, Isha, in her orange dress, wandered between rows of books, each page turning igniting new dreams in her curious mind.",
      "Isha, in her vibrant orange dress, wandered through the gardens, her long hair trailing behind. Each flower seemed to dance with her.",
      "At the village festival, Isha's traditional dress shimmered under the starry sky, her joyful dance captivating the hearts of onlookers.",
      "In the tranquility of dawn, Isha practiced ancient dances, her orange dress mirroring the sunrise, her movements as graceful as the morning breeze.",
      "Through the bustling streets, Isha moved with elegance, her traditional dress a testament to her heritage, her dark eyes reflecting wisdom beyond her years.",
      "Isha's laughter filled the air as she played traditional melodies, her orange dress swirling, matching with the music's soulful notes.",
    ]
  };

  const randomNumber = Math.floor(Math.random() * stories[character].length);

  console.log('section 1, randomNumber', randomNumber, ', out of ', stories[character].length);

  return stories[character][randomNumber];
};

export const getStorySection2 = (character: string, sidekickName: string): string => {
  const stories: { [key: string]: string[] } = {
    Cameron: [
      `One sunny afternoon, ${character} stumbled upon a curious scene: Cameron, a cat with a magnifying glass, was intently studying footprints. Intrigued, ${character} offered to help, marking the start of a unique friendship.`,
      `During a leisurely walk in the park, ${character} noticed Cameron, the cat detective, examining something intently. As ${character} approached, they discovered a hidden clue leading to an exciting adventure.`,
      `Early one morning, ${character} met Cameron, the cat detective, on the lookout for a lost kitten. Their successful search not only found the kitten but also forged a new friendship.`,
      `${character} always enjoyed visiting Cameron, the cat detective. With his magnifying glass and a nose for mystery, Cameron was never without an intriguing puzzle. Together, they often solved little mysteries around the neighborhood.`,
      `${character} had heard tales of Cameron, the cat detective, and finally met him while he was investigating a mysterious case. Intrigued by his methods, ${character} quickly became Cameron's willing assistant.`,
      `One day, ${character} went to the park to enjoy the sunshine and stumbled upon Cameron, who was examining footprints with his magnifying glass. Together, they uncovered a lost pet's trail, starting their friendship.`,
      `${character} decided to explore the bustling town market and there, ran into Cameron, deeply focused on a set of mysterious clues. Their shared curiosity led them to solve a small mystery, cementing their bond.`,
      `In an effort to find a quiet place to read, ${character} visited the local library, where they met Cameron, engrossed in a detective novel. Intrigued, they ended up collaborating on a real-life puzzle, marking the beginning of their friendship.`,
      `While returning books at the library, ${character} encountered Cameron, who was on the hunt for a hidden treasure map among the shelves. Their adventure to find it together sparked a new friendship.`,
      `Seeking a bit of adventure, ${character} headed to the local fair, where they bumped into Cameron. He was in the middle of a detective case, and ${character}'s help led them to solve the case together, starting a lasting friendship.`,
      `On a leisurely walk through the town, ${character} found Cameron scrutinizing a peculiar map. Curious, they offered to help, and their successful quest forged a deep friendship, ready for the next adventure.`,
      `${character} was on the way to the park for a picnic and suddenly saw Cameron following a trail of clues. They joined forces, and by the end of the day, they had cracked the case and become inseparable friends.`,
      `After hearing about Cameron's detective adventures, ${character} went to an alley behind the bakery, hoping to meet him. ${character} found him investigating a clue, and by helping him, they started a friendship filled with mysteries.`,
      `Wanting to learn more about detective work, ${character} sought out Cameron at the town square, where he was known to solve mysteries. They quickly became involved in a new case, sealing their friendship.`,
      `Eager for a new book, ${character} visited the library and met Cameron, the detective cat, researching a case. ${character} offered to help, and by 2pm they had solved it. This was the start of a great friendship, both ready for new adventures.`,
      `${character} visited the town's cozy café and found Cameron poring over a map. Over cups of hot cocoa, they reminisced about past adventures and plotted their next quest, eager for the mysteries that lay ahead.`,
      `${character} was walking through the park and spotted Cameron, an old friend, examining something under a tree. Joining him, they laughed about old times and decided it was time for a new detective adventure, setting off with renewed zeal.`,
      `During a routine visit to the library, ${character} reunited with Cameron the cat detective, ${character}'s longtime detective partner. Among the stacks of books, they shared stories and decided it was high time for another thrilling mystery to solve together.`,
      `${character} went to the annual town festival and bumped into Cameron the cat detective, ${character}'s childhood friend and fellow mystery enthusiast. Amidst the festivities, they agreed to tackle a new case together, their excitement for adventure as strong as ever.`,
      `${character} was visiting the local market and came across Cameron the cat detective examining a curious artifact. After catching up on old times, they agreed it was the perfect start for their next big adventure.`,
      `It was a sunny day and ${character} took a walk in the park and noticed Cameron scrutinizing a clue under a bush. 'Need a paw with that?' ${character} joked. Laughing, they teamed up, ready for whatever mystery awaited them next.`,
      `${character} ventured into the library to find a book on mysteries, only to find Cameron with his nose in a detective novel. 'Fancy solving a real one together?' ${character} asked. A new adventure began with a nod from Cameron.`,
      `Walking through the bustling market, ${character} bumped into Cameron the cat detective, who was on the tail of a sneaky mystery. 'Join me, and let's solve this together!' Cameron proposed. A partnership was born, adventure in their eyes.`,
      `Early one morning, ${character} found Cameron pondering over a map at the town square. 'I could use your insight,' Cameron said, inviting character into a journey full of intrigue and friendship.`,
      `One day ${character} was relaxing in the garden when Cameron the cat detecitve leapt over, a curious expression on his face. 'I've found something peculiar,' he said, showing character a cryptic note. Together, they set off, eager for the adventure it promised.`,


    ],
    Windy: [
      `It was a sunny day when ${character} met Windy. She was sitting on a hilltop, her staff pointed skyward, changing the clear sky to a spectacle of colors. They were soon having adventures together.`,
      `In the middle of a thunderstorm, ${character} found shelter under a tree, where Windy was controlling the fierce weather with her staff. They quickly became friends.`,
      `${character} and Windy had been friends since childhood. They often explored the wilderness, with Windy's mood swings bringing unexpected weather, making every adventure unpredictable.`,
      `${character} wandered through the meadow and encountered Windy practicing her weather spells. Fascinated by her ability to summon wind and rain, ${character} eagerly joined her for an adventure.`,
      `${character} took a walk in the town square and spotted Windy altering the weather with a flick of her staff. Intrigued, ${character} approached, beginning a journey of magical discoveries.`,
      `One cloudy afternoon, ${character} found Windy atop a hill, her mood as turbulent as the weather. Their meeting sparked an immediate bond, and together they set off to explore.`,
      `One evening, ${character} took a walk under the starlit sky and met Windy. Her staff glowed with moonlight, they chatted and became friend quickly. The both were ready for new adventures together.`,
      `Taking a stroll at the forest's edge, ${character} discovered Windy mastering a whirlwind. Leaves twirled around her, and fascinated, ${character} stepped into her world of weather wonders.`,
      `On a market day, ${character} went to the village square and saw Windy changing the weather. With a swirl of her staff, she called forth rain. Seeing ${character} drenched Windy apologies and asked for help on an adventure.`,
      `Walking by the old oak tree, ${character} found Windy, her mood as stormy as the sky. Their conversation brought out the sun, and together, they set off on a new adventure.`,
      `${character} went to the lake to enjoy the quiet, but found Windy creating fog patterns on the water. Mesmerized, ${character} joined her for a journey through mystical mists.`,
      `As the sun set, ${character} climbed to the cliff's edge and met Windy, who danced with the wind. Intrigued by her mastery over the weather, they decided to explore the world's wonders together.`,
      `In a sunny glade, ${character} saw Windy bending sunlight through the trees. Her elemental magic was enchanting, and ${character} eagerly joined her on her magical quests.`,
      `During a morning walk in the woods, ${character} heard a voice. 'Watch this!' Windy exclaimed, waving her staff to clear the clouds. 'Wow!' ${character} replied, 'Let's see what else we can do together!' And so, a new adventure began.`,
      `While visiting the village fair, ${character} bumped into Windy. 'Trying to change the weather for the fair?' ${character} teased. Windy chuckled, 'You know me too well. Join me?' Together, they went off on a new caper.`,
      `On a hike to the old mill, ${character} found Windy arguing with a cloud. 'You can't rain on my parade!' she declared. Amused, ${character} joined in, 'Need help?' 'Always!' Windy grinned, leading the way to their next escapade.`,
      `${character} went to the lake to skip stones and suddenly Windy appeared. 'I can make those skip farther!' she boasted, swirling her staff. ${character} laughed, 'Show me!' A friendship was forged, and together they set off on a new adventure.`,
      `${character} took a bike trip in the woods and got a flat tire. Luckily, ${character}'s best friend Windy appeared. 'Need some help?' Windy offered. After fixing the tire, she invited ${character} to join her on a new quest.`,
      `On a sunny afternoon, ${character} went to the park to fly a kite but got it stuck in a tree. Windy, an old friend came along and used her magic to retrieve it. 'Thanks! Ready for another adventure?' she asked ${character} with a smile.`,
      `${character} decided to explore the nearby hill for a picnic. Suddenly, Windy appeared with a gust of wind. 'Long time no see!' she exclaimed. They reminisced about old times and plotted their next exciting adventure together.`,
      `${character} went to visit a local fair and was trying to win a prize when Windy, a very close friend from ages ago, showed up. 'Remember our quests?' she asked. They laughed, catching up before Windy proposed a brand new adventure.`,
    ],
    Timber: [
      `${character} was wandering when a deep, friendly voice echoed. It was Timber, the wise oak, whose smile and magical acorns had granted many a wish to those who visited. Timber said to ${character}, "Let's go on an adventure."`,
      `Wandering through the whispering woods, ${character} came across Timber, whose wise words and magical acorns hinted at many untold adventures. A deep bond of friendship quickly formed.`,
      `Timber, the talking oak, had always fascinated ${character}. His grand presence and magical acorns that granted wishes were a marvel. ${character} asked Timber if he would join on an adventure.`,
      `While exploring the forest, ${character} was drawn to Timber's laughter. The stories told by the talking tree sparked an adventurous spirit in ${character}. ${character} and Timber were now eager for the magical realms that lay ahead.`,
      `One sunny afternoon, ${character} went for a stroll through the local forest and stumbled upon Timber. His kind eyes and soothing voice offered guidance and friendship for the adventures that lay ahead.`,
      `In the enchanting heart of the forest, ${character} came across Timber. The tree's stories hinted at the adventures and magical quests that awaited them, promising an uncharted journey of friendship and discovery.`,
      `${character} went out to the deepest part of the forest to visit his best friend Timber the talking tree. Timber has an idea for a new adventure, and ${character} asked if he could join in.`,
      `${character} went for a walk in the woods and sought shade under old friend Timber's vast branches. The talking tree shared his wisdom and a shimmering acorn. ${character} make a wish that they could go on a magical adventure together.`,
      `${character} ventured into the forest seeking tranquility and found it under Timber's sprawling branches. ${character} was shocked when the tree talked. ${character} soon realised that Timber was very wise and they became close friends.`,
      `While exploring the forest's depths, ${character} stumbled upon Timber, who shared stories of ancient magic. Their bond formed quickly, united by a shared love of adventure and the mysteries of the woods.`,
      `${character} sought refuge under Timber's branches one starlit evening. Their conversation, filled with tales of yore and laughter, cemented a friendship that promised countless adventures ahead.`,
      `${character} went on a stroll in the woods. Beneath Timber's shade, ${character} found more than shelter; a friend. As Timber imparted wisdom, they planned their first of many quests together.`,
      `${character} went on a forest walk and was drawn to Timber's enchanting stories that whispered of distant lands. Together, they dreamed up future journeys, sealing a friendship bound by curiosity.`,
      `${character} wandered into the forest, where Timber called out, 'Lost are we?' 'Just exploring,' ${character} replied. They laughed and were soon planning an adventure as old as Timber's tales.`,
      `${character} went wandering in the forest and sat beneath Timber, the tree asked, 'Ready for a quest?' ${character} grinned, 'Always!' And so, they plotted a journey into the unknown, their friendship deepening with every word.`,
      `${character} went out to find the magical talking tree Timber. ${character} approached Timber with curiosity. 'Heard any interesting tales?' ${character} asked. 'Only if you're willing to be part of one...'`,
      
    ],
    Echo: [
      `${character} took a walk to the heart of the forest and found Echo, an elf with a mischievous smile. His magic was as wild as his hair, and ${character} was soon swept into a world of adventure.`,
      `${character} went to explore the enchanted forest. ${character} found Echo, strumming his lyre. His rhymes filled the air, weaving a magical mood, readying for an adventure.`,
      `Echo, with his lyrical voice and elfin charm, came to visit his old friend ${character}. Together, they shared tales and songs, their spirits alight, eager for the journey ahead.`,
      `${character} explored the forest, Echo's harmonious tunes echoed softly. Meeting amidst the old oaks, they felt an immediate kinship, and were ready plotting a new adventure.`,
      `With a lyre in hand and a rhyme on his lips, Echo met ${character} during an eclipse. Together they laughed, their spirits in sync, ready for a journey to the world's brink.`,
      `Echo's rhymes weaved a mystical net, capturing ${character}'s heart at sunset. In the glow of the twilight, they spun dreams of flight, to lands unseen and heights supreme.`,
      `Rhyme after rhyme, Echo charmed the air, as ${character} listened with wonder and flair. Their bond formed in lyrical streams, setting sail to chase fantastical dreams.`,
      `In the glade, Echo's song echoed true, enchanting ${character} under the sky so blue. With a shared love for rhyme and verse, they embarked on quests diverse.`,
      `Under the moon's soft glow, Echo's rhymes ebbed and flowed. ${character} was caught in the spell, together they'd wander where stories dwell.`,
      `${character} took a walk by the river and came upon Echo, rhymes flowed like a stream. ${character} was mesmerized, as if in a dream. Together, they vowed to explore lands unknown, their excitement grown.`,
      `One night, ${character} and best friend Echo the elf were sitting by an old tree. Echo sang of big adventures waiting for them, and they both felt excited.`,
      `While walking in the woods, ${character} found Echo singing. They quickly became friends, sharing stories and dreams of going on a big adventure.`,
      `${character} and old friend Echo the elf went for a walk through the quiet woods. They remembered old tales and talked about faraway places. The soon were excited planning their next quest.`,
      `One morning, ${character} went for a walk in the woods and heard Echo's enchanting songs. As they sang together, they remembered their old bond and looked forward to new adventures.`,
      `On a sunny day, ${character} visited the meadow where Echo was playing his lyre. Their laughter over shared songs strengthened their friendship, readying them for the adventures ahead.`,
      `${character} heard a tune and followed the melody to a hidden glade, where Echo awaited with a smile. As best friends, they excitedly planned their next big adventure, united by music.`,
      `Seeking inspiration, ${character} wandered into the forest, finding Echo among the trees. Their instant connection over music blossomed into a deep friendship, eager for new journeys.`,
      `${character} went exploring the valley and stumbled upon Echo, who greeted them with a verse. Old friends reunited, they dreamed of lands to explore, bound by a love of rhyme.`,
      `During a leisurely stroll, ${character} was drawn to Echo's lyrical voice under the oak. They shared stories and songs, adding to a friendship that promised countless adventures.`,
      `${character} set out on a walk to clear their mind, Echo's harmonious tunes guided them to a clearing. Together, they found solace in their friendship and excitement for the unknown.`,
      `While seeking a new adventure, ${character} was captivated by Echo's performance on a hilltop. Recognizing each other as kindred spirits, they vowed to embark on a magical journey together.`,
      `One day, ${character} ventured to the stream, where Echo's rhymes were a dream. 'Let's travel far and wide,' they chimed, as friends, side by side.`,
      `${character} found Echo in the glen, rhyming tales now and then. 'Together, let's seek what's new,' they said, 'and chase dreams, me and you.'`,
      `In the woods, so deep and fine, ${character} met Echo, line by line. 'With every rhyme, a step we take,' they vowed, 'to adventures, we'll awake.'`,
      `Echo played a tune so bright, ${character} came running, into the light. 'Our bond,' they sang, 'will never bend, through every tale, to every end.'`,
      `Under the moon's soft glow, ${character} listened to Echo, low. 'To lands unknown, shall we depart?' A rhyme that sealed their friendship's heart.`,
    ]
  };

  const randomNumber = Math.floor(Math.random() * stories[sidekickName].length);

  console.log('section 2, randomNumber', randomNumber, ', out of ', stories[sidekickName].length);

  return stories[sidekickName][randomNumber];
};

export const getStorySection3 = (character: string, sidekickName: string): string => {
  const stories: string[] = [
    `${character} and ${sidekickName} went for a walk and stumbled upon an ancient, dusty bookshop. In the back, they found a curious storybook with shimmering pages. As they opened it, a whirlwind of colors enveloped them.`,
    `${character} and ${sidekickName} took a trip into town and discovered a hidden alley leading to a magical market. Amidst the stalls, a glowing portal caught their eyes. They entered.`,
    `While exploring the outskirts of the town, ${character} and ${sidekickName} were suddenly engulfed in a mysterious fog. When it cleared, they found themselves in an unrecognizable land.`,
    `One day during a walk a peculiar flyer advertising a magical tour caught the eyes of ${character} and ${sidekickName}. They boarded a whimsical bus, only to disembark in a world beyond their wildest dreams.`,
    `During a mundane walk, a sudden whirlwind swept up ${character} and ${sidekickName}. As they braced themselves, the wind settled, revealing surroundings unlike anything they'd seen before.`,
    `${character} and ${sidekickName} were playing hide-and-seek one day when they stumbled into a hidden cave. Inside, a glowing portal beckoned them to step into a world of adventure. They entered.`,
    `One starry night, ${character} and ${sidekickName} spotted a falling star. They made a wish for an adventure, and to their astonishment, they were whisked away to an unknown land.`,
    `One Saturday in December ${character} was given the chore to tidy the attic and so enlisted ${sidekickName} to help. They randomly found a peculiar compass. When they touched it together, the compass spun wildly, transporting them to a mysterious realm.`,
    `${character} and ${sidekickName} went to the local library to find some books to read. However there they discovered a map with no destination. Intrigued, they followed it, leading them to a gateway into a fantastical world. They passed through.`,
    `${character} and ${sidekickName} decided to go to a local festival and were drawn to a magician's tent. The magician performed a spell, and suddenly, they found themselves in a whole new world.`,
    `It was a Sunday when ${character} and ${sidekickName} were chasing a runaway balloon into the forest. This is where they found a tree with a door in its trunk. Opening it, they stepped into another realm.`,
    `A mysterious old man offered ${character} and ${sidekickName} a pair of enchanted boots. Upon wearing them, they were transported to a land far beyond their imagination.`,
    `One day ${character} and ${sidekickName} were on hike and got caught in a thunderstorm. They took shelter in a cave, only to find it was a gateway to a magical, distant land.`,
    `${character} and ${sidekickName} followed a trail of mystical fireflies that led them to a hidden grove. There, a mystical vortex spiraled, leading them to an unknown adventure.`,
    `${character} and ${sidekickName} took a walk and came across a deserted mansion. The ground trembled, and there was a bright flash. They rushed out to find themselves in a new world.`,
    `One sunny afternoon, ${character} and ${sidekickName} stumbled upon an ancient, ivy-covered archway in the forest. As they passed under it, a bright light enveloped them, and they found themselves standing in a completely different world.`,
    `${character} and ${sidekickName} decided to go to their favourite museum and were examining an old, mystical globe. When they both touched it simultaneously, the room spun wildly, and when it stopped, they were no longer in the museum, but somewhere entirely new.`,
    `${character} and ${sidekickName} went out to explore a cave and discovered strange, glowing crystals. As ${sidekickName} touched one, a portal opened, pulling them into a vortex. When their feet finally touched the ground, they were in a place like no other.`,
    `One day during a walk ${character} and ${sidekickName} got caught in a thunder storm. They took shelter in an abandoned hut. As lightning struck nearby, the floor beneath them gave way, sending them tumbling down into a tunnel that led to an unknown, magical land.`,
    `While walking through the market, ${character} and ${sidekickName} were drawn to a curious, cloaked vendor selling 'magic carpets'. Skeptically they stepped onto one, and to their astonishment, it soared into the sky, transporting them to a new realm.`,
    `${character} and ${sidekickName} found a peculiar, ornate mirror in an attic. As they gazed into it, the mirror's surface rippled, and they stepped through, emerging into a world that defied all they knew.`,
    `${character} and ${sidekickName} went out to their favourite book shop and discovered a book with a map that leapt off the page in a whirlwind of colors, sweeping them into its illustrated world.`,
    `During a hike, ${character} and ${sidekickName} noticed a peculiar tree with a door in its trunk. Curiosity led them inside, and as the door closed, they were instantly whisked away to an unknown land.`,
    `One day ${character} and ${sidekickName} went to visit the town fair. They dared each other to ride the mysterious 'Ferris Wheel to the Stars'. They got on board and as it spun, the stars aligned, opening a gateway to another realm right at the top.`,
    `One sunny day ${character} and ${sidekickName} decided to got fishing. They caught an enchanted fish that offered to grant a wish. They wished for adventure, and in a splash, they were spirited away to a fantastical land.`,
  ];

  const randomNumber = Math.floor(Math.random() * stories.length);

  console.log('section 3, randomNumber', randomNumber, ', out of ', stories.length);


  return stories[randomNumber];
};

export const getStorySection4 = (character: string, sidekickName: string, setting: string): string => {
  const stories: { [key: string]: string[] } = {
    "Wild West": [
      `Blinking under the hot sun, ${character} and ${sidekickName} found themselves amidst a bustling Wild West town. Cowboys on horseback trotted by, as the distant sound of a saloon piano played.`,
      `The smell of dusty plains and the sound of spurs jingling greeted ${character} and ${sidekickName} as they stood on the main street of a Wild West town, squinting at the bright sun.`,
      `With a sudden whirl of dust and tumbleweeds, ${character} and ${sidekickName} appeared in the middle of a gold rush town. Excitement buzzed in the air, with prospectors and outlaws mingling alike.`,
      `Gazing at the vast open plains, ${character} and ${sidekickName} could hardly believe they were in the Wild West. The sound of a distant train whistle promised new adventures.`,
      `As ${character} and ${sidekickName} wandered through the dusty streets, they marveled at the wooden saloons and bustling markets of the Wild West.`,
      `The Wild West unfolded before ${character} and ${sidekickName}, revealing vast open plains, distant mountains, and the occasional silhouette of a cowboy on horseback.`,
      `In the heart of the Wild West, ${character} and ${sidekickName} found themselves amidst lively townsfolk, creaky windmills, and the distant sound of lonesome harmonicas.`,
      `Under the vast, open sky, ${character} and ${sidekickName} gazed at the endless horizon of the Wild West, where dreams and adventures awaited.`,
      `All at once, ${character} and ${sidekickName} found themselves in a sunny Wild West town, surprised by the wooden shops and horses everywhere.`,
      `Right away, the Wild West showed ${character} and ${sidekickName} its exciting places, like noisy saloons and the beautiful sunset.`,
      `Suddenly, ${character} and ${sidekickName} were in the Wild West, amazed by its dusty lands, rolling tumbleweeds, and the far-off sound of a train.`,
      `Suddenly finding themselves in a busy market, ${character} and ${sidekickName} saw cowboys and sellers everywhere, feeling the buzz of the Wild West.`,
      `Out of nowhere, ${character} and ${sidekickName} were standing under the hot sun of a Wild West town, looking around at all the action.`,
      `Just like that, they were in a lively Wild West town, feeling excited about all the adventures waiting for them.`,
      `Suddenly, ${character} and ${sidekickName} were on a dusty street, surrounded by sounds of music and laughter from nearby.`,
      `In a flash, they were in the middle of a Wild West street, smelling horses and hearing music.`,
      `Without warning, ${character} and ${sidekickName} found themselves near a Wild West town, ready for fun and adventure around every corner.`,

    ],
    "Pirate Ships": [
      `${character} and ${sidekickName} suddenly stood on a tropical island. In the distance, pirate ships sailed the turquoise waters, flags waving.`,
      `Suddenly, ${character} and ${sidekickName} found themselves in a noisy tavern full of pirates, hearing stories of sea battles and hidden treasures.`,
      `Before they knew it, ${character} and ${sidekickName} were in a busy port town, surrounded by the smell of the ocean and ships waiting for adventure.`,
      `In a blink, they were at the port, watching flags wave and listening to the loud market full of people.`,
      `Out of nowhere, the smell of the sea and the sound of waves welcomed ${character} and ${sidekickName} to their new adventure.`,
      `Walking on stone streets by the sea, ${character} and ${sidekickName} suddenly felt the excitement of a place filled with pirates and adventures.`,
      `Everything was new and exciting for ${character} and ${sidekickName} at the market, surrounded by people talking about treasures and islands.`,
      `Suddenly, they were looking at ships, each with a different flag, making them dream of adventures on the high seas.`,
      `The first breath of salty air and the distant songs of sailors made ${character} and ${sidekickName} eager to explore more.`,
      `Without warning, ${character} and ${sidekickName} stepped into a world of swaying ships and shouting sailors, their eyes wide with wonder.`,
      `One moment they were there, and the next, ${character} and ${sidekickName} found themselves among pirates, the air thick with tales of the sea.`,
      `All at once, ${character} and ${sidekickName} were on the bustling docks, ships creaking and seagulls calling, the promise of adventure in the air.`,
      `Out of the blue, ${character} and ${sidekickName} found themselves in the middle of a pirate haven, surrounded by the buzz of adventure and the scent of saltwater.`,
      `Suddenly, the quiet world of ${character} and ${sidekickName} was replaced by the roar of the sea and the busy life of a pirate town.`,
      `In an instant, ${character} and ${sidekickName} were lost in a sea of pirates, their senses alive with the sounds and smells of maritime life.`,
      `From nowhere, ${character} and ${sidekickName} were thrust into a lively port, where stories of the sea were as plentiful as the waves.`,
      `With no warning, ${character} and ${sidekickName} stood on a pier, watching as sailors hauled treasures from exotic lands, their hearts racing with excitement.`,
      `As if by magic, ${character} and ${sidekickName} appeared at a crowded seaside market, where every stall held the promise of pirate stories and seafaring mysteries.`,
      `Abruptly, ${character} and ${sidekickName} were amidst a throng of sea adventurers, the air filled with the anticipation of the next voyage into the unknown.`,
    ],
    "Medieval Kingdom": [
      `Suddenly, ${character} and ${sidekickName} found themselves in a medieval fair, surrounded by jesters, knights, and the hum of the bustling crowd.`,
      `In an instant, the modern world vanished, leaving ${character} and ${sidekickName} standing in a lively town square, with the medieval kingdom unfolding around them.`,
      `As if by magic, ${character} and ${sidekickName} were transported to a kingdom of towering castles and busy markets, their eyes wide with wonder.`,
      `Without any warning, ${character} and ${sidekickName} were amidst a throng of villagers in a medieval kingdom, where every corner whispered tales of yore.`,
      `Abruptly, the world around ${character} and ${sidekickName} shifted, and they stood at the edge of a medieval village, its cobblestone streets leading towards adventure.`,
      `One moment they were home, the next, ${character} and ${sidekickName} gazed upon a medieval kingdom, its spires reaching into the sky.`,
      `Out of nowhere, ${character} and ${sidekickName} stumbled upon a medieval festival, complete with minstrels and acrobats, their senses alight with the kingdom's vibrant life.`,
      `From the mist, a medieval kingdom emerged before ${character} and ${sidekickName}, a place where fantasy and history blended into a vivid panorama.`,
      `${character} and ${sidekickName} found themselves walking the streets of an ancient kingdom, marvelling at the sights and sounds of medieval life.`,
      `The scene changed in a heartbeat; ${character} and ${sidekickName} were now in the midst of a medieval kingdom, the air filled with the sound of distant lutes and the smell of roasting meats.`,
      `Suddenly, the air shimmered, and ${character} and ${sidekickName} were standing in a bustling medieval market, surrounded by the scent of fresh herbs and the sound of bartering.`,
      `With a blink, ${character} and ${sidekickName} were transported to the heart of a medieval kingdom, where the clang of armor and the laughter of children filled the air.`,
      `Out of thin air, ${character} and ${sidekickName} appeared on a stone path, leading to a castle towering over a lively medieval village.`,
      `A flash of light, and ${character} and ${sidekickName} found themselves in a medieval courtyard, with knights practicing in the background and banners waving in the wind.`,
      `In an instant, the modern world faded, and ${character} and ${sidekickName} were standing at the gates of a majestic castle, the heart of a medieval kingdom.`,
      `Magically, ${character} and ${sidekickName} found themselves at a village fair within a medieval kingdom, complete with music, dancing, and jesters juggling.`,
      `Without warning, the surroundings changed, and ${character} and ${sidekickName} were exploring the narrow streets of a medieval town, busy with the hustle and bustle of daily life.`,
      `A gust of wind swept them away, and when it settled, ${character} and ${sidekickName} were overlooking a medieval kingdom from a high castle tower.`,
      `As if by a spell, ${character} and ${sidekickName} were suddenly with a group of medieval villagers, gathering in the town square for a celebration.`,
      `With a mysterious fog lifting, ${character} and ${sidekickName} found themselves on the edge of a medieval kingdom, its walls and towers full of adventures.`,
    ],
  };

  const randomNumber = Math.floor(Math.random() * stories[setting].length);

  console.log('section 4, randomNumber', randomNumber, ', out of ', stories[setting].length);

  return stories[setting][randomNumber];
};



export const getStorySection5 = (character: string, sidekickName: string, setting: string, villain: string): string => {
  const stories: { [key: string]: { [key:string]: string[] } } = {
    "Sir Slumber": {
      "Wild West": [
        `In the dusty streets of a Wild West town, ${character} and ${sidekickName} encountered Sir Slumber, slumped against a saloon. His yawns were contagious, lulling the town to sleep. 'Quick, we must act before the whole town dozes off!' ${character} exclaimed.`,
        `Aboard a stagecoach, ${character} and ${sidekickName} were ambushed by Sir Slumber. His sleepy aura began to take effect, and the horses slowed to a stop. 'We need to wake up the town before it's too late,' ${character} whispered, fighting the urge to sleep.`,
        `Under the blazing sun, ${character} and ${sidekickName} found Sir Slumber trying to nap in a sheriff's cell. 'He’s planning to put the entire Wild West to sleep!' ${sidekickName} gasped. They knew they had to act fast.`,
        `While wandering through a quiet Wild West town, ${character} and ${sidekickName} stumbled upon a wanted poster: 'Beware of Sir Slumber!' it read. 'He's been casting sleep spells across the frontier,' a local explained. `,
        `In the saloon, amidst whispers and hushed tones, ${character} and ${sidekickName} overheard tales of Sir Slumber. 'He's turning the West into a ghost town, one nap at a time,' a cowboy remarked.`,
        `At the edge of town, ${character} saw a mysterious figure draped in a cloak, yawning lazily. 'That's Sir Slumber,' ${sidekickName} murmured. 'Let's follow him!' But in a blink, he was gone. `,
        `During a lively hoedown, the music suddenly stopped. 'Sir Slumber's been seen near the canyon,' announced the sheriff. ${character} and ${sidekickName} exchanged glances, knowing this was their chance to act.`,
        `As the sun set over the Wild West, ${character} and ${sidekickName} found a sleepy town with no one awake. 'Sir Slumber must've been here,' ${sidekickName} said. They set up camp, pondering their next move.`,
        `In a sleepy Wild West town, ${character} and ${sidekickName} discovered a poster of Sir Slumber. 'He's casting sleep spells,' a local whispered. The duo knew they had to stop this drowsy villain.`,
        `At a saloon, ${character} and ${sidekickName} overheard tales of Sir Slumber, the sleep-spelling villain. Intrigued, they planned to find him, wondering where this elusive figure could strike next.`,
        `Spotting a cloaked figure in town, ${sidekickName} whispered, 'That's Sir Slumber.' But he vanished suddenly. ${character} knew they faced a challenging task: finding a villain who could disappear anytime.`,
        `As news spread of Sir Slumber's sighting near the canyon, ${character} and ${sidekickName} prepared to track him down. They needed a plan to outsmart a villain who put towns to sleep.`,
        `Finding a town unnaturally asleep, ${character} and ${sidekickName} realized Sir Slumber had been there. They camped out, strategizing their next move against a villain who could be snoozing just anywhere.`
      ],
      "Pirate Ships": [
        `Overhearing a pirate conversation, ${character} and ${sidekickName} heard tales of Sir Slumber. The pirates spoke of a drowsy villain casting sleep over entire crews, leaving ships adrift in the moonlit sea.`,
        `${character} and ${sidekickName} saw a wanted poster stuck to a post saying Sir Slumber. 'He's putting entire islands to sleep,' a worried sailor said. They knew they had a new mission.`,
        `Gazing at the starry sky, ${character} and ${sidekickName} overheard a pirate's tale of Sir Slumber, who lulled a rival crew into eternal slumber, claiming their treasure and disappearing into the night.`,
        `While trading stories with a seasoned sailor, ${character} and ${sidekickName} learned of Sir Slumber's antics. 'He's a menace on the seas, turning bustling ships into floating ghost towns,' the sailor grumbled.`,
        `Resting after their journey, ${character} and ${sidekickName} found an old scroll depicting Sir Slumber. It showed his drowsy figure, looming over a ship with a crew asleep at the helm.`,
        `${character} and ${sidekickName} noticed a crowd gathered around a storyteller. He was recounting Sir Slumber's legendary sleep spells, powerful enough to quiet the rowdiest of pirate gatherings.`,
        `A group of children playing pirates caught ${character} and ${sidekickName}'s attention. 'I'm Sir Slumber, you'll all sleep now!' one declared, pretending to cast a sleepy spell on his mates.`,
        `A parrot squawked a peculiar rhyme as ${character} and ${sidekickName} passed by: 'Sir Slumber's near, the sea's so clear, not a wave in sight, for he sleeps tight.' It was oddly unsettling.`
      ],
      "Medieval Kingdom": [
        `In the bustling market square, ${character} and ${sidekickName} heard merchants grumbling about Sir Slumber. His magic had caused a deep sleep to fall over the northern tower, halting the bustling day's trade.`,
        `A weary bard sang to ${character} and ${sidekickName} about Sir Slumber, whose drowsy spell had silenced the once lively castle banquets, leaving feasts uneaten and songs unsung in the great hall.`,
        `In the shadow of the castle, ${character} and ${sidekickName} found a sleeping guard. Whispers among the townsfolk spoke of Sir Slumber's spell, turning the kingdom's vigilance into unending slumber.`,
        `At the royal library, ${character} and ${sidekickName} stumbled upon a dozing librarian. Legends in dusty tomes told of Sir Slumber's quest for eternal peace through the kingdom's eternal sleep.`,
        `While exploring a quiet village, ${character} and ${sidekickName} noticed villagers struggling to stay awake. Gossip at the well revealed Sir Slumber's recent visit, leaving a trail of drowsiness and yawns.`,
        `As ${character} and ${sidekickName} roamed the castle grounds, they noticed knights snoring in formation. Rumor had it that Sir Slumber, on a stealthy night visit, had enchanted the entire garrison.`,
        `It was the middle of the harvest festival, ${character} and ${sidekickName} found farmers asleep amidst their stalls. Tales spread of Sir Slumber passing through, his yawning spell causing a wave of drowsiness over the celebration.`,
        `They explored the royal gardens, ${character} and ${sidekickName} discovered court jesters sleeping on lutes. Whispers among courtiers spoke of Sir Slumber's mischievous antics, turning the king's entertainment into a slumbering spectacle.`,
        `They came across a knight's tournament, ${character} and ${sidekickName} found the arena eerily quiet, with competitors in a deep sleep. Sir Slumber had struck again, turning the event into a dreamland.`,
        `They found themselves in the town square, ${character} and ${sidekickName} saw children dozing off during their games. Elderly villagers recounted old legends of Sir Slumber, a figure who sought quiet by blanketing the world in sleep.`,
        `While exploring, ${character} and ${sidekickName} found a group of knights asleep on horseback. Tales swirled of Sir Slumber's recent visit, casting a soporific spell that even the horses couldn't resist.`,
        `At the bustling marketplace, ${character} and ${sidekickName} were puzzled to see merchants dozing among their wares. It was said that Sir Slumber had wandered through, leaving a trail of slumber behind.`,
        `They wandered into a tranquil monastery, ${character} and ${sidekickName} found monks asleep over ancient texts. Legends spoke of Sir Slumber, whose presence turned the monastery's haven of knowledge into a dormitory.`,
        `During a walk, ${character} and ${sidekickName} noticed the town's guards fast asleep at their posts, victims of Sir Slumber's drowsy magic, rendering the town's defenses a sleeping spectacle.`,
        `They ventured into the village inn, ${character} and ${sidekickName} observed people snoozing into their meals. Whispers circulated about Sir Slumber's visit the night before, his yawning curse affecting everyone within the inn's walls.`,
      ],
    },
    "Lilith Liar": {
      "Wild West": [
        `Around a campfire, cowboys shared legends with ${character} and ${sidekickName}, speaking in hushed tones of Lilith the Liar, whose deceptions had led many a cowboy astray on phantom gold rush trails.`,
        `As ${character} and ${sidekickName} wandered the streets, posters caught their eye, warning of Lilith's arrival - a master of deceit, known for spinning tall tales that ensnared the minds of the unsuspecting.`,
        `At the local saloon, ${character} and ${sidekickName} overheard gamblers whispering about Lilith, who'd swindled the entire town with her lies, leaving a trail of confusion and mistrust in her wake.`,
        `In the shadowy corners of an old Wild West outpost, ${character} and ${sidekickName} heard of Lilith, a master of lies whose deceptive stories had led many into perilous misadventures.`,
        `Whispers reached ${character} and ${sidekickName} about Lilith, spinning yarns so convincing that even the most skeptical townsfolk were swayed, her mask hiding more than just her face.`,
        `While exploring a Wild West outpost, ${character} and ${sidekickName} encountered Lilith. Disguised as a fortune teller, her web of lies ensnared the townsfolk, leading them on fruitless quests for riches.`,
        `During a high noon showdown, whispers circulated among the onlookers. ${character} and ${sidekickName} learned of Lilith, a notorious liar whose tales had instigated duels based on false accusations and deceit.`,
        `In a crowded marketplace, ${character} and ${sidekickName} heard tales of Lilith, whose lies were said to be as dangerous as an outlaw's bullet, misleading entire towns into chaos.`,
        `A group of miners shared stories with ${character} and ${sidekickName} about Lilith, whose false maps and promises of undiscovered mines had led many into the perilous wilderness, never to return.`,
        `At a deserted ranch, ${character} and ${sidekickName} found evidence of Lilith's deception. Her manipulative influence had turned a once-thriving community into a ghost town filled with broken dreams.`,
      ],
      "Pirate Ships": [
        `While examining an old compass, ${character} and ${sidekickName} overheard pirates discussing Lilith, who'd tricked many with tales of enchanted islands, leading them on fruitless voyages.`,
        `As ${character} and ${sidekickName} strolled along the dock, they stumbled upon a message in a bottle. It was from Lilith, spinning a web of lies about a hidden pirate utopia.`,
        `A mysterious parrot landed beside ${character} and ${sidekickName}, squawking about Lilith's latest lie: a ghost ship filled with gold, sending many pirates chasing after mere whispers and fog.`,
        `During a card game with some old sailors, ${character} and ${sidekickName} heard about Lilith, whose deceptive promises of a safe passage through treacherous waters led many to their doom.`,
        `A sea shanty hummed by an old sea dog caught the ears of ${character} and ${sidekickName}. It told of Lilith, a siren of lies, leading sailors astray`,
        `In the market near the docks, ${character} and ${sidekickName} noticed a crowd gathered around a storyteller, who spun tales of Lilith's lies, leading many sailors to chase phantom treasures.`,
        `${character} and ${sidekickName} overheard whispers among the crew of a docked ship. Tales of Lilith the Liar, her charm as dangerous as the sea, having led many to chase illusions.`,
        `While navigating through the bustling port, ${character} and ${sidekickName} encountered an old sea witch. She warned them of Lilith, whose lies were as shifting and perilous as the ocean's tides.`,
        `At a bonfire by the beach, ${character} and ${sidekickName} listened to sailors sing about Lilith, a mistress of deception, whose false promises had caused many a ship's downfall.`,
      ],
      "Medieval Kingdom": [
        `While exploring the castle gardens, ${character} and ${sidekickName} overheard the royal guard discussing Lilith's latest deception, which had thrown the court into chaos and led knights on a wild quest.`,
        `At the village fair, a wandering bard caught the attention of ${character} and ${sidekickName} with his ballad about Lilith, whose lies had ensnared even the wisest sages and wizards.`,
        `In the town square, ${character} and ${sidekickName} witnessed a commotion. A merchant accused Lilith of enchanting his goods, leading to a frenzy of customers seeking imaginary treasures.`,
        `During their stroll through the bustling marketplace, ${character} and ${sidekickName} stumbled upon a mysterious scroll. It spoke of Lilith's fabrications that had lured adventurers into the dangerous, enchanted forests.`,
        `As night fell over the kingdom, ${character} and ${sidekickName} joined a group of travelers around a campfire. They shared tales of Lilith, whose alluring lies had led many into perilous quests.`,
        `In the castle's grand hall, ${character} and ${sidekickName} noticed a portrait of Lilith. The court jester whispered tales of her deceit, casting illusions over the kingdom's unsuspecting inhabitants.`,
        `A group of children playing near the river told ${character} and ${sidekickName} about Lilith's latest lie, which had turned the once-tranquil waters into a source of mysterious whispers.`,
        `An old knight recounted to ${character} and ${sidekickName} his encounter with Lilith, whose beguiling words had once led him on a fruitless quest for a legendary grail.`,
        `At the monastery, ${character} and ${sidekickName} learned of an ancient prophecy from a wise monk, foretelling the chaos and confusion that Lilith's web of lies would bring to the kingdom.`,
        `While wandering the forest, ${character} and ${sidekickName} found strange symbols on trees, believed to be the work of Lilith, guiding unsuspecting travelers deeper into her realm of deception.`,
      ],
    },
    "Captain Quicksand": {
      "Wild West": [
        `In a bustling saloon, ${character} and ${sidekickName} overheard cowboys discussing Captain Quicksand's latest threat. They whispered about his plans to sink the town, transforming the dusty streets into his pirate domain.`,
        `As ${character} and ${sidekickName} admired a Wild West sunset, a poster caught their eye: 'Beware Captain Quicksand!' It warned of a pirate turning the desert into treacherous, sinking sands.`,
        `A traveling merchant told ${character} and ${sidekickName} tales of Captain Quicksand's greed, describing how he planned to engulf entire cities in quicksand to expand his pirate empire across the West.`,
        `At the town's edge, ${character} and ${sidekickName} noticed strange, sandy patches. Rumors were swirling among the townsfolk that Captain Quicksand was nearby, ready to claim the land for his pirate kingdom.`,
        `A group of children playing 'cowboys and pirates' acted out the fearsome tale of Captain Quicksand. His ability to liquefy the ground was as much a legend here as in the high seas.`,
        `An old miner, spinning tales by the campfire, warned ${character} and ${sidekickName} about Captain Quicksand. He spoke of the pirate's ambition to sink entire mining towns for his aquatic empire.`,
        `In the local newspaper, ${character} read an article about Captain Quicksand. The story described his recent attempts to turn the town square into quicksand, thwarted only by the chilly night air.`,
        `During a horseback ride, ${character} and ${sidekickName} came across a wagon stuck in quicksand. The driver cursed Captain Quicksand, blaming him for the treacherous conditions on the once-solid trail.`,
        `A mysterious, bearded stranger at the general store hinted at Captain Quicksand's presence nearby. He spoke of the pirate's greed and his unique power to transform the arid land into sinking sand.`,
        `While exploring the outskirts of town, ${character} and ${sidekickName} found a half-buried chest. Inside were maps marked with areas threatened by Captain Quicksand's quicksand-transforming abilities.`,
      ],
      "Pirate Ships": [
        `${character} and ${sidekickName} overheard sailors speaking of Captain Quicksand. They whispered tales of entire ships vanishing into the sea, their hulls swallowed by the quicksand created by the notorious pirate.`,
        `${character} and ${sidekickName} got talking to a retired pirate. He shared stories of Captain Quicksand and his ambition to sink islands, transforming them into treacherous quicksand traps for unsuspecting sailors.`,
        `In a hidden cove, ${character} discovered a map leading to Captain Quicksand's lair. The map showed treacherous waters where the infamous pirate transformed the ocean floor into deadly quicksand traps.`,
        `${character} and ${sidekickName} heard rumours of Captain Quicksand's latest conquest. He had reportedly sunk a rival pirate's stronghold, turning their beach fortress into a vast, sinking quicksand pit.`,
        `Lying in the street ${sidekickName} found a stranded sailor, a survivor from a vessel engulfed by Captain Quicksand's quicksand sea. He recounted the harrowing tale of escape from the pirate's deadly trap.`,
        `While walking around ${character} heard a bard's song about Captain Quicksand. The ballad described his lust for gold and power, turning prosperous ports into his quicksand-filled pirate domains.`,
        `While wandering near the docks, ${character} and ${sidekickName} overheard a group of sailors discussing Captain Quicksand's latest attack, where he transformed a rival's ship deck into a treacherous quicksand trap.`,
        `A mysterious old mariner approached ${character} and ${sidekickName}, whispering legends of Captain Quicksand, who could turn the ocean's surface into sinking sands, claiming ships and their treasures.`,
        `In the bustling market, a trader sold ${character} a curious artifact, rumored to protect against Captain Quicksand's powers, who was known to sink entire ships into the ocean's depths.`,
        `While exploring the town, ${character} and ${sidekickName} noticed wanted posters of Captain Quicksand, infamous for creating quicksand whirlpools that swallowed unsuspecting ships along the coast.`,
        `During a quiet evening, ${character} and ${sidekickName} heard a bard's tale of Captain Quicksand. His songs of greed and treachery echoed through the night, warning of the pirate's quicksand curses.`,
      ],
      "Medieval Kingdom": [
        `${character} and ${sidekickName} listened to a crier's announcement about Captain Quicksand's latest heist, using his quicksand powers to plunder a royal caravan en route to the castle.`,
        `A wandering minstrel sang to ${character} and ${sidekickName} about the feared Captain Quicksand. His ballad described a pirate who could turn castle moats into quicksand, trapping knights and seizing treasures.`,
        `${character} and ${sidekickName} overheard a group of knights discussing Captain Quicksand's threat to the kingdom, his ability to turn battlefields into sinking sands leaving soldiers in disarray.`,
        `${sidekickName} discovered an old map lying around showing Captain Quicksand's secret lair. The legend spoke of a pirate turning stone fortresses into quicksand, vanishing without a trace.`,
        `During their stroll through the marketplace, ${character} and ${sidekickName} saw various wares claiming to ward off Captain Quicksand’s quicksand spells, a testament to the pirate's feared reputation.`,
        `While exploring, ${character} and ${sidekickName} overheard a mysterious cloaked figure telling a story to a group of children about Captain Quicksand, who once turned the king's banquet hall into a quicksand trap.`,
        `As they rested, ${character} and ${sidekickName} heard a bard's tale of Captain Quicksand's exploits, enchanting listeners with stories of a pirate who turned the royal gardens into treacherous, sinking grounds.`,
        `A worried merchant approached ${character} and ${sidekickName}, seeking advice. He whispered rumors about Captain Quicksand planning to turn the upcoming tournament grounds into a perilous quicksand pit.`,
        `In the shadowy corners of the marketplace, ${character} and ${sidekickName} eavesdropped on a conversation about Captain Quicksand's latest scheme to turn the cathedral’s solid floors into his treacherous quicksand during a grand ceremony.`,
        `A traveling healer shared with ${character} and ${sidekickName} a potion rumored to counteract Captain Quicksand’s spells, revealing the widespread fear of his ability to liquefy solid castle walls.`,
      ],
    },

  };

  const randomNumber = Math.floor(Math.random() * stories[villain][setting].length);

  console.log('section 5, randomNumber', randomNumber, ', out of ', stories[villain][setting].length);

  return stories[villain][setting][randomNumber];
};

export const getStorySectionCliffhanger = (setting: string, villain: string): string[] => {
  const stories: { [key: string]: string[] } = {
    "Wild West": [
      "Seek a sheriff's help",
      "Explore the gold mines",
      "Join a cattle drive",
      "Attend a rodeo show",
      "Ride into the sunset",
      "Plan a heist",
      "Spy on outlaws",
      "Visit the local saloon",
      "Investigate the town",
      "Gather a posse",
      "Learn cowboy skills",
      "Uncover hidden treasures",
      "Track down a map",
      "Attend a barn dance",
      "Cross the desert",
      "Challenge a duelist",
      "Befriend native tribes",
      "Take a nap",
      "Find some food",
      "Explore a ghost town",
      "Join a wagon caravan",
      "Track a coyote pack",
      "Enter a lasso contest",
      "Pan for gold in the river",
      "Help a lost prospector",
      "Attend a frontier fair",
      "Learn from a wise shaman",
      "Defend a ranch from bandits",
      "Ride through a canyon",
      "Investigate an abandoned mine",
      "Rescue a stagecoach",
      "Duel at high noon",
      "Uncover an old treasure map",
      "Negotiate with railroad tycoons",
      "Scout for wild mustangs",
      "Decipher old town legends",
      "Solve a saloon mystery",
      "Cross the prairie on horseback",
      "Repair a broken telegraph line",
      "Write a poem",
      "Write a song",
      "Learn a dance",
      "Go to cowboy school"
    ],
    "Pirate Ships": [
      "Search for Sir Slumber",
      "Join a pirate ship",
      "Explore a hidden cove",
      "Rescue a marooned sailor",
      "Seek a mermaid's help",
      "Find a treasure map",
      "Attend a pirate feast",
      "Spy on a captain",
      "Join a ship crew",
      "Dive for sunken gold",
      "Learn seafaring legends",
      "Sail to distant islands",
      "Repair an old ship",
      "Uncover a sea monster",
      "Trade in exotic ports",
      "Outsmart a rival crew",
      "Discover a secret bay",
      "Sing at a sailor's tavern",
      "Help a stranded whale",
      "Learn ancient shipbuilding",
      "Decode a mysterious map",
      "Rescue captured parrots",
      "Infiltrate an enemy fleet",
      "Solve an old sailor's riddle",
      "Attend a sea festival",
      "Befriend a sea serpent",
      "Craft a powerful amulet",
      "Cook a pirate's feast",
      "Plan a daring heist",
      "Enter a ship race",
      "Explore a sunken city",
      "Challenge a legendary pirate",
      "Seek advice from a wise octopus",
      "Seek out a sea witch",
      "Repair a cursed compass",
      "Host a pirate council",
      "Take a nap",
      "Find some food",
      "Write a poem",
      "Write a song",
      "Learn a dance",
      "Go to pirate school"
    ],
    "Medieval Kingdom": [
      "Attend a royal banquet",
      "Train with knights",
      "Explore the enchanted forest",
      "Compete in a jousting tournament",
      "Seek wisdom from the court wizard",
      "Join a secret guild",
      "Investigate a haunted castle",
      "Rescue a mythical creature",
      "Navigate a labyrinth",
      "Brew potions with an alchemist",
      "Solve riddles in the royal library",
      "Hunt for a legendary beast",
      "Participate in a bard's contest",
      "Uncover an ancient prophecy",
      "Embark on a holy pilgrimage",
      "Defend the village from raiders",
      "Decipher ancient runes",
      "Find a hidden treasure in the catacombs",
      "Help a lost prince or princess",
      "Cure a mysterious plague",
      "Forge a magical sword",
      "Guard the royal crown",
      "Attend a masquerade ball",
      "Investigate a dungeon",
      "Outwit a cunning thief",
      "Cross a treacherous bridge",
      "Meet a wandering minstrel",
      "Converse with talking animals",
      "Reveal a hidden passage",
      "Battle a fierce dragon",
      "Tame a wild unicorn",
      "Solve a dispute among knights",
      "Discover a lost city",
      "Break a powerful curse",
      "Navigate through a mystical fog",
      "Rescue a wizard's familiar",
      "Decipher a cryptic scroll",
      "Survive a knight's duel",
      "Unravel a royal conspiracy",
      "Face a sorcerer's trial",
      "Take a nap",
      "Find some food",
      "Write a poem",
      "Write a song",
      "Learn a dance",
      "Go to knight school",
      "Go to magician school"
    ],
    "Sir Slumber": [
      "Find Sir Slumber",
      "Find info on Sir Slumber",
      "Explore Sir Slumbers history",
      "Plan to defeat Sir Slumbers",
      "Join Sir Slumbers crew",
      "Learn about sleep magic",
      "Search for Sir Slumber's lair",
      "Uncover Sir Slumber's weakness",
      "Gather clues about Sir Slumber",
      "Infiltrate Sir Slumber's house",
      "Confront Sir Slumber's minions",
      "Find an antidote to sleep magic",
      "Consult a sage about Sir Slumber",
      "Intercept Sir Slumber's plans",
      "Challenge Sir Slumber in a dream",
      "Sabotage Sir Slumber's sleep potion",
      "Protect the village from Sir Slumber",
      "Negotiate with Sir Slumber",
      "Unravel Sir Slumber's enchantments",
      "Steal Sir Slumber's magical staff",
      "Attend Sir Slumber's masquerade",
      "Spy on Sir Slumber's gathering",
      "Locate Sir Slumber's secret chamber",
      "Infiltrate Sir Slumber's dream realm",
      "Search for Sir Slumber's lair",
      "Dispel Sir Slumber's sleep spell",
      "Intercept Sir Slumber's sleeping potion",
      "Resist Sir Slumber's drowsy magic",
      "Capture Sir Slumber's snooze crystal",
      "Consult a sage about Sir Slumber",
      "Prepare a wake-up surprise for Sir Slumber",
      "Find the antidote to Sir Slumber's power",
      "Outsmart Sir Slumber in a dream challenge",
      "Organize a resistance against Sir Slumber",
      "Sabotage Sir Slumber's slumber party",
      "Steal Sir Slumber's pillow of eternal sleep",
      "Attend Sir Slumber's midnight banquet",
      "Decipher Sir Slumber's dream diary",
      "Wake up the town from Sir Slumber's spell",
      "Design a device to counteract sleep magic",
      "Unearth the history of Sir Slumber's curse",
      "Challenge Sir Slumber to a no-sleep competition",
      "Pretend to be Sir Slumber",
      "Disguise as one of Sir Slumber's allies",
      "Decode messages from Sir Slumber's spies",
      "Spy on Sir Slumber"
    ],
    "Lilith Liar": [
      "Examine Lilith Liar's mask",
      "Follow Lilith Liar's trail",
      "Decode Lilith Liar's riddles",
      "Expose Lilith Liar's deceptions",
      "Attend Lilith Liar's ball",
      "Confront Lilith Liar directly",
      "Gather evidence against Lilith Liar",
      "Disguise as one of Lilith's allies",
      "Outsmart Lilith Liar in a game",
      "Resist Lilith Liar's temptations",
      "Infiltrate Lilith Liar's hideout",
      "Unravel Lilith Liar's web of lies",
      "Dismantle Lilith Liar's network",
      "Reveal Lilith Liar's true identity",
      "Counter Lilith Liar's manipulations",
      "Escape Lilith Liar's illusion",
      "Recruit help to challenge Lilith",
      "Learn Lilith Liar's backstory",
      "Thwart Lilith Liar's next scheme",
      "Interrogate Lilith Liar's accomplices",
      "Mimic Lilith Liar's disguise",
      "Attend Lilith's masquerade gala",
      "Decode Lilith's secret messages",
      "Sabotage Lilith's deceptive plans",
      "Unveil Lilith's hidden agenda",
      "Negotiate with Lilith Liar",
      "Disrupt Lilith's grand performance",
      "Steal Lilith's mysterious artifact",
      "Spy on Lilith's secret meeting",
      "Challenge Lilith to a duel of wits",
      "Plant false information for Lilith",
      "Rescue a victim of Lilith's lies",
      "Reveal the truth about Lilith",
      "Unmask Lilith at a royal event",
      "Find the source of Lilith's power",
      "Create an antidote to Lilith's lies",
      "Hijack Lilith's caravan of deceit",
      "Set a trap for Lilith Liar",
      "Gather a council against Lilith",
      "Seek a seer's advice on Lilith",
      "Decode messages from Lilith's spies",
      "Spy on Lilith",
      "Pretend to be Lilith"
    ],
    "Captain Quicksand": [
      "Identify Captain Quicksand's traps",
      "Find Captain Quicksand's hidden treasure",
      "Find Captain Quicksand's quicksand pit",
      "Challenge Captain Quicksand to a duel",
      "Infiltrate Captain Quicksand's pirate crew",
      "Sabotage Captain Quicksand's ship",
      "Uncover Captain Quicksand's past",
      "Investigate Captain Quicksand's ship",
      "Disrupt Captain Quicksand's sinking ritual",
      "Rescue hostages from Captain Quicksand",
      "Negotiate with Captain Quicksand",
      "Steal Captain Quicksand's weather-changing artifact",
      "Discover Captain Quicksand's weakness",
      "Form an alliance against Captain Quicksand",
      "Track Captain Quicksand through the desert",
      "Reveal Captain Quicksand's true identity",
      "Protect the town from Captain Quicksand",
      "Intercept Captain Quicksand's supply line",
      "Decode messages from Captain Quicksand's spies",
      "Unearth Captain Quicksand's buried secrets",
      "Defend against Captain Quicksand's onslaught",
      "Explore Captain Quicksand's abandoned hideout",
      "Infiltrate Captain Quicksand's ship",
      "Distract Captain Quicksand with a decoy",
      "Seek a legendary weapon to defeat Captain Quicksand",
      "Learn ancient lore about Captain Quicksand",
      "Resist Captain Quicksand's persuasive offers",
      "Attend a secretive meeting about Captain Quicksand",
      "Rescue a key figure from Captain Quicksand",
      "Navigate a labyrinth set by Captain Quicksand",
      "Gather a council to strategize",
      "Stop Captain Quicksand's plan",
      "Sabotage Captain Quicksand's quicksand traps",
      "Negotiate with Captain Quicksand's rivals",
      "Impersonate one of Captain Quicksand's lieutenants",
      "Investigate Captain Quicksand's mysterious past",
      "Seek wisdom from a sage about defeating Captain Quicksand",
      "Journey to confront Captain Quicksand",
      "Spy on Captain Quicksand",
      "Pretend to be Captain Quicksand"
    ],
  };

  const randomNumbersSetting: number[] = [];
  for (let i = 0; i < 2; i++) {
    while (true) {
      const randomNumber = Math.floor(Math.random() * stories[setting].length);
      if (!randomNumbersSetting.includes(randomNumber)) {
        randomNumbersSetting.push(randomNumber);
        console.log('cliffhanger options, randomNumber', randomNumber, ', out of ', stories[setting].length);
        break;
      }
    }
  }

  const randomNumberVillain = Math.floor(Math.random() * stories[villain].length);

  console.log('cliffhanger options, randomNumber', randomNumberVillain, ', out of ', stories[villain].length);

  return [stories[setting][randomNumbersSetting[0]], stories[setting][randomNumbersSetting[1]], stories[villain][randomNumberVillain]];
};