import React, { createContext, useContext, useState, useEffect } from 'react';

interface AppContextType {
    id: number;
    storyLength: string;
    storyAge: string;
    character: string;
    helper: string;
    villain: string;
    setting: string;
    interests: string[];
    chapter: number;
    title: string;
    storyId: number;
    isFullScreen: boolean;
    setId: (age: number) => void;
    setStoryLength: (storyLength: string) => void;
    setStoryAge: (storyLength: string) => void;
    selectCharacter: (character: string) => void;
    selectHelper: (helper: string) => void;
    selectVillain: (villain: string) => void;
    selectSetting: (setting: string) => void;
    selectInterests: (interests: string[]) => void;
    addInterest: (interest: string) => void;
    removeInterest: (interest: string) => void;
    setChapter: (chapter: number) => void;
    setTitle: (title: string) => void;
    setStoryId: (storyId: number) => void;
    toggleFullScreen: () => void;
}

interface AppProviderProps {
    children: React.ReactNode;
}

const AppContext = createContext<AppContextType | undefined>(undefined);

export const useAppContext = () => {
    const context = useContext(AppContext);
    if (!context) {
        throw new Error("useAppContext must be used within AppProvider");
    }
    return context;
}

export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
    const [id, setId] = useState<number>(0);
    const [storyLength, setStoryLength] = useState<string>("");
    const [storyAge, setStoryAge] = useState<string>("");
    const [character, setCharacter] = useState<string>("");
    const [helper, selectHelper] = useState<string>("");
    const [villain, selectVillain] = useState<string>("");
    const [setting, selectSetting] = useState<string>("");
    const [interests, selectInterests] = useState<string[]>([]);
    const [chapter, setChapter] = useState<number>(1);
    const [title, setTitle] = useState<string>("");
    const [storyId, setStoryId] = useState<number>(0);
    const [isFullScreen, setIsFullScreen] = useState<boolean>(false);

    const toggleFullScreen = () => {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen().catch((e) => {
                console.error(`Error attempting to enable full-screen mode: ${e.message} (${e.name})`);
            });
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            }
        }
    };

    const addInterest = (interest: string) => {
        if (!interests.includes(interest)) {
            selectInterests(prev => [...prev, interest]);
        }
    };

    const removeInterest = (interest: string) => {
        selectInterests(prev => prev.filter(i => i !== interest));
    };

    const selectCharacter = (character: string) => {
        setCharacter(character);
    };

    useEffect(() => {
        const handleFullscreenChange = () => {
            setIsFullScreen(!!document.fullscreenElement);
        };

        document.addEventListener('fullscreenchange', handleFullscreenChange);

        return () => {
            document.removeEventListener('fullscreenchange', handleFullscreenChange);
        };
    }, []);

    return (
        <AppContext.Provider value={{ id, storyLength, storyAge, character, helper, villain, setting, 
            interests, chapter, title, storyId, isFullScreen, 
            setId, setStoryLength, setStoryAge, selectCharacter, selectHelper, selectVillain, selectSetting, selectInterests, 
            addInterest, removeInterest, setChapter, setTitle, setStoryId, toggleFullScreen }}>
            {children}
        </AppContext.Provider>
    );
}
