import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useAppContext } from '../../AppContext';
import './StoryApp.css'; 

const settings = [
  { name: "Medieval Kingdom", image: `${process.env.PUBLIC_URL}/images/medieval_kingdom3.png`, fgImage: `${process.env.PUBLIC_URL}/images/bg_green2.png`, description: "Castles, knights, dragons" },
  { name: "Wild West", image: `${process.env.PUBLIC_URL}/images/wild_west3.png`, fgImage: `${process.env.PUBLIC_URL}/images/bg_sand.png`, description: "Saloons, cowboys, outlaws" },
  { name: "Pirate Ships", image: `${process.env.PUBLIC_URL}/images/pirate_ships3.png`, fgImage: `${process.env.PUBLIC_URL}/images/bg_green2.png`, description: "Pirates, ships, treasure" },
];

const characters = [
  { name: "Nat", image: `${process.env.PUBLIC_URL}/images/nat_fs2.png` },
  { name: "Isha", image: `${process.env.PUBLIC_URL}/images/isha_fs2.png` }, 
  { name: "Mia", image: `${process.env.PUBLIC_URL}/images/mia_fs2.png` },
  { name: "Alex", image: `${process.env.PUBLIC_URL}/images/alex_fs2.png` },
];

type Setting = {
  name: string;
  image: string;
  fgImage: string;
  description: string;
};


function SettingSelection() {
  const { character, isFullScreen, selectSetting, toggleFullScreen } = useAppContext();

  const [selectedSettingIndex, setSelectedSettingIndex] = useState(0);
  const [shuffledSettings, setShuffledSettings] = useState<Setting[]>([]);

  useEffect(() => {
    const settingsToShuffle = [...settings]; // Copy characters
    shuffleArray(settingsToShuffle); // Shuffle characters
    setShuffledSettings(settingsToShuffle); // Set shuffled characters to state
  }, []);

  const shuffleArray = (array: Setting[]) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]]; // Swap elements
    }
  }

  const characterImage = characters.find((c) => c.name === character)?.image;
  const characterName = characters.find((c) => c.name === character)?.name;

  const navigate = useNavigate();

  const handleSwipe = (direction: string) => {
    if(direction === 'right') {
      setSelectedSettingIndex((prevIndex) =>
        prevIndex === shuffledSettings.length - 1 ? 0 : prevIndex + 1
      );
    } else if (direction === 'left') {
      setSelectedSettingIndex((prevIndex) =>
        prevIndex === 0 ? shuffledSettings.length - 1 : prevIndex - 1
      );
    }
  };

  const handleGoBack = () => {
    navigate("/character");
  }

  const navigateToNext = () => {
    selectSetting(shuffledSettings[selectedSettingIndex].name);
    navigate("/villain");
  }

  // Render conditionally to ensure shuffledSettings is loaded
  if (shuffledSettings.length === 0) {
    return <div>Loading...</div>; // Or any other placeholder content
  }

  return (
    <div className="dimension-container">
      <div className="selection-container px-0"
        style={{
          backgroundImage: `url(${shuffledSettings[selectedSettingIndex].image})`,
          backgroundSize: 'cover', 
          backgroundRepeat: 'no-repeat', 
        }}>
        <div className="nav-buttons">
          <i className="bi bi-arrow-left-circle back-button" onClick={handleGoBack}></i>
          <i className={`bi ${isFullScreen ? 'bi-fullscreen-exit' : 'bi-fullscreen'} fullscreen-button`} onClick={toggleFullScreen}></i>
        </div>

        <h1 className="text-80 text-center title-font-bold">Choose your setting</h1>
        <div className="selection-navigation">
          <div className="left-button" onClick={() => handleSwipe('left')}>
            <i className="bi bi-caret-left-fill"></i>
          </div>
          <div className="right-button" onClick={() => handleSwipe('right')}>
            <i className="bi bi-caret-right-fill"></i>
          </div>
        </div>
        <div className="character-display background">
          <img src={process.env.PUBLIC_URL + shuffledSettings[selectedSettingIndex].fgImage} alt={"background"} />
        </div>
        <div className="character-display">
          <img src={process.env.PUBLIC_URL + characterImage} alt={characterName} />
        </div>
        <h1 className="text-large2 title-font-bold">{shuffledSettings[selectedSettingIndex].name}</h1>
        <h3 className="text-large3 title-font-bold">{shuffledSettings[selectedSettingIndex].description}</h3>
        <button className="next-button" onClick={navigateToNext}>Next</button>
        <div className="pagination">
          {shuffledSettings.map((_, index) => (
            <div key={index} className={`dot ${index === selectedSettingIndex ? 'active' : ''}`} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default SettingSelection;
