import React from 'react';
import { useAppContext } from '../../AppContext';
import { useNavigate } from "react-router-dom";
import './InterestsSelection.css';
import './StoryApp.css';

const settings = [
  { name: "Medieval Kingdom", image: `${process.env.PUBLIC_URL}/images/medieval_kingdom3.png`, fgImage: `${process.env.PUBLIC_URL}/images/bg_green2.png` },
  { name: "Wild West", image: `${process.env.PUBLIC_URL}/images/wild_west3.png`, fgImage: `${process.env.PUBLIC_URL}/images/bg_sand.png` },
  { name: "Pirate Ships", image: `${process.env.PUBLIC_URL}/images/pirate_ships3.png`, fgImage: `${process.env.PUBLIC_URL}/images/bg_green2.png` },
];

const characters = [
  { name: "Nat", image: `${process.env.PUBLIC_URL}/images/nat_fs2.png` },
  { name: "Isha", image: `${process.env.PUBLIC_URL}/images/isha_fs2.png` }, 
  { name: "Mia", image: `${process.env.PUBLIC_URL}/images/mia_fs2.png` },
  { name: "Alex", image: `${process.env.PUBLIC_URL}/images/alex_fs2.png` },
];

const InterestsSelection: React.FC = () => {
  const navigate = useNavigate();

  const { character, setting, interests, isFullScreen, addInterest, removeInterest, toggleFullScreen } = useAppContext();

  const settingImage = settings.find((c) => c.name === setting)?.image;
  const fgSettingImage = settings.find((c) => c.name === setting)?.fgImage;

  const column1 = "12%";
  const column2 = "28%";
  const column3 = "72%";
  const column4 = "88%";
  const row1 = "28%";
  const row2 = "44%";
  const row3 = "60%";
  const row4 = "20%";
  const row5 = "36%";
  const row6 = "52%";


  const interestsMapping = [
    { name: "Dinosaurs", emoticon: "🦖", position: { top: row1, left: column1} },
    { name: "Football", emoticon: "⚽", position: { top: row2, left: column1} },
    { name: "Cats", emoticon: "🐱", position: { top: row3, left: column1} },
    { name: "Dogs", emoticon: "🐶", position: { top: row4, left: column2} },
    { name: "Music", emoticon: "🎵", position: { top: row5, left: column2} },
    { name: "Movies", emoticon: "🎬", position: { top: row6, left: column2} },
    { name: "Computer Games", emoticon: "🎮", position: { top: row4, left: column3} },
    { name: "Playground", emoticon: "🛝", position: { top: row5, left: column3} },
    { name: "Cars", emoticon: "🚗", position: { top: row6, left: column3} },
    { name: "Art", emoticon: "🎨", position: { top: row1, left: column4} },
    { name: "Puzzles", emoticon: "🧩", position: { top: row2, left: column4} },
    { name: "Bikes", emoticon: "🚲", position: { top: row3, left: column4} },
  ];

  const characterImage = characters.find((c) => c.name === character)?.image;
  const characterName = characters.find((c) => c.name === character)?.name;

  const toggleInterest = (interest: string) => {
    if (interests.includes(interest)) {
        removeInterest(interest);
    } else if (interests.length < 2) {
        addInterest(interest);
    }
  }

  const handleGoBack = () => {
    navigate("/helper");
  }

  const navigateToNext = () => {
    navigate("/story");
  }

  return (
    <div className="dimension-container">
      <div className="selection-container px-0"
        style={{
          backgroundImage: `url(${settingImage})`,
          backgroundSize: 'cover', 
          backgroundRepeat: 'no-repeat', 
        }}
      >
        <div className="nav-buttons">
          <i className="bi bi-arrow-left-circle back-button" onClick={handleGoBack}></i>
          <i className={`bi ${isFullScreen ? 'bi-fullscreen-exit' : 'bi-fullscreen'} fullscreen-button`} onClick={toggleFullScreen}></i>
        </div>
        <h1 className="text-center title-font-bold">Choose 2 interests</h1>
        <div className="interests-container">
          {interestsMapping.map((interest, index) => (
            <button 
              key={index} 
              className={`emoticon-button btn ${interests.includes(interest.name) ? 'btn-primary' : 'btn-outline-primary'}`} 
              onClick={() => toggleInterest(interest.name)}
              data-emoticon={interest.emoticon}
              style={{
                position: 'absolute',
                top: interest.position.top,
                left: interest.position.left
              }}
            >
            </button>
          ))}
        </div>

        <div className="selected-interests">
          <h3 className='text-center title-font-bold'>Selected interests</h3>
          <div className="d-flex justify-content-center">
              {interests.map((interest, index) => (
                <button onClick={() => removeInterest(interest)} className="button-paper interest-button" role="button">{interest}</button>
              ))}
          </div>
        </div>

        <div className="character-display background">
          <img src={process.env.PUBLIC_URL + fgSettingImage} alt={"background"} />
        </div>
        <div className="character-display">
          <img src={process.env.PUBLIC_URL + characterImage} alt={characterName} />
        </div>
        <button className="next-button" onClick={navigateToNext}>Next</button>
        <div className="bottom-section"></div>
      </div>
    </div>
  );
}

export default InterestsSelection;

