import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useAppContext } from '../../AppContext';
import './StoryApp.css'; 

const characters = [
  { name: "Isha", image: `${process.env.PUBLIC_URL}/images/isha_fs2.png`, ageDisplay: "(4-7 yrs)", age: "6 year old", description: "Likes making friends" }, 
  { name: "Nat", image: `${process.env.PUBLIC_URL}/images/nat_fs2.png`, ageDisplay: "(0-6 yrs)", age: "4 year old", description: "Funny and excitable" }, 
  { name: "Mia", image: `${process.env.PUBLIC_URL}/images/mia_fs2.png`, ageDisplay: "(5-9 yrs)", age: "7 year old", description: "Enjoys imaginery worlds" },  
  { name: "Alex", image: `${process.env.PUBLIC_URL}/images/alex_fs2.png`, ageDisplay: "(8+ yrs)", age: "12 year old", description: "Loves skating tricks" }, 
];

type Character = {
  name: string;
  image: string;
  ageDisplay: string;
  age: string;
  description: string;
};


function CharacterSelection() {
  const { isFullScreen, selectCharacter, toggleFullScreen, setChapter, setStoryAge } = useAppContext();

  const [selectedCharacterIndex, setSelectedCharacterIndex] = useState(0);
  const [shuffledCharacters, setShuffledCharacters] = useState<Character[]>([]);

  useEffect(() => {
    const charsToShuffle = [...characters]; // Copy characters
    shuffleArray(charsToShuffle); // Shuffle characters
    setShuffledCharacters(charsToShuffle); // Set shuffled characters to state
  }, []);

  const shuffleArray = (array: Character[]) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]]; // Swap elements
    }
  }

  const navigate = useNavigate();

  const handleSwipe = (direction: string) => {
    if(direction === 'right') {
      setSelectedCharacterIndex((prevIndex) =>
        prevIndex === shuffledCharacters.length - 1 ? 0 : prevIndex + 1
      );
    } else if (direction === 'left') {
      setSelectedCharacterIndex((prevIndex) =>
        prevIndex === 0 ? shuffledCharacters.length - 1 : prevIndex - 1
      );
    }
  };

  const handleGoBack = () => {
    setChapter(-1);
    navigate("/");
  }

  const navigateToNext = () => {
    setStoryAge(shuffledCharacters[selectedCharacterIndex].age);
    selectCharacter(shuffledCharacters[selectedCharacterIndex].name);
    navigate("/setting");
  }

  // Render conditionally to ensure shuffledCharacters is loaded
  if (shuffledCharacters.length === 0) {
    return <div>Loading...</div>; // Or any other placeholder content
  }

  return (
    <div className="dimension-container">
      <div className="selection-container px-0"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg_green_blue2.png)`,
          backgroundSize: 'cover', 
          backgroundRepeat: 'no-repeat', 
        }}>
        <div className="nav-buttons">
          <i className="bi bi-arrow-left-circle back-button" onClick={handleGoBack}></i>
          <i className={`bi ${isFullScreen ? 'bi-fullscreen-exit' : 'bi-fullscreen'} fullscreen-button`} onClick={toggleFullScreen}></i>
        </div>
        <h1 className="text-80 text-center title-font-bold">Choose your character</h1>
        <div className="selection-navigation">
          <div className="left-button" onClick={() => handleSwipe('left')}>
            <i className="bi bi-caret-left-fill"></i>
          </div>
          <div className="right-button" onClick={() => handleSwipe('right')}>
            <i className="bi bi-caret-right-fill"></i>
          </div>
        </div>
        <div className="character-display">
          <img src={process.env.PUBLIC_URL + shuffledCharacters[selectedCharacterIndex].image} alt={shuffledCharacters[selectedCharacterIndex].name} />
        </div>
        <h1 className="text-large2 title-font-bold">{shuffledCharacters[selectedCharacterIndex].name} {shuffledCharacters[selectedCharacterIndex].ageDisplay}</h1>
        <h3 className="text-large3 title-font-bold">{shuffledCharacters[selectedCharacterIndex].description}</h3>
        <button className="next-button" onClick={navigateToNext}>Next</button>
        <div className="pagination">
          {shuffledCharacters.map((_, index) => (
            <div key={index} className={`dot ${index === selectedCharacterIndex ? 'active' : ''}`} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default CharacterSelection;
