import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useAppContext } from '../../AppContext';
import './StoryApp.css'; 

const settings = [
  { name: "Medieval Kingdom", image: `${process.env.PUBLIC_URL}/images/medieval_kingdom3.png`, fgImage: `${process.env.PUBLIC_URL}/images/bg_green2.png` },
  { name: "Wild West", image: `${process.env.PUBLIC_URL}/images/wild_west3.png`, fgImage: `${process.env.PUBLIC_URL}/images/bg_sand.png` },
  { name: "Pirate Ships", image: `${process.env.PUBLIC_URL}/images/pirate_ships3.png`, fgImage: `${process.env.PUBLIC_URL}/images/bg_green2.png` },
];

const helpers = [
  { name: "Echo the elf", image: `${process.env.PUBLIC_URL}/images/echo_fs2.png`, description: "Rhymes the story" },
  { name: "Windy the weather witch", image: `${process.env.PUBLIC_URL}/images/windy_fs2.png`, description: "Moody but loving" },
  { name: "Cameron the cat detective", image: `${process.env.PUBLIC_URL}/images/cameron_fs2.png`, description: "Bravely seeks truth" },
  { name: "Timber the talking tree", image: `${process.env.PUBLIC_URL}/images/timber_fs2.png`, description: "Drops magical acorns" },
];  

type Helper = {
  name: string;
  image: string;
  description: string;
};

function HelperSelection() {
  const { setting, isFullScreen, selectHelper, toggleFullScreen } = useAppContext();

  const [selectedHelperIndex, setSelectedHelperIndex] = useState(0);
  const [shuffledHelpers, setShuffledHelpers] = useState<Helper[]>([]);

  useEffect(() => {
    const charsToShuffle = [...helpers]; // Copy characters
    shuffleArray(charsToShuffle); // Shuffle characters
    setShuffledHelpers(charsToShuffle); // Set shuffled characters to state
  }, []);

  const shuffleArray = (array: Helper[]) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]]; // Swap elements
    }
  }

  const navigate = useNavigate();

  const settingImage = settings.find((c) => c.name === setting)?.image;
  const fgSettingImage = settings.find((c) => c.name === setting)?.fgImage;

  const handleSwipe = (direction: string) => {
    if(direction === 'right') {
      setSelectedHelperIndex((prevIndex) =>
        prevIndex === shuffledHelpers.length - 1 ? 0 : prevIndex + 1
      );
    } else if (direction === 'left') {
      setSelectedHelperIndex((prevIndex) =>
        prevIndex === 0 ? shuffledHelpers.length - 1 : prevIndex - 1
      );
    }
  };

  const handleGoBack = () => {
    navigate("/villain");
  }

  const navigateToNext = () => {
    selectHelper(helpers[selectedHelperIndex].name);
    navigate("/interests");
  }

  // Render conditionally to ensure shuffledSettings is loaded
  if (shuffledHelpers.length === 0) {
    return <div>Loading...</div>; // Or any other placeholder content
  }

  return (
    <div className="dimension-container">
      <div className="selection-container px-0"
        style={{
          backgroundImage: `url(${settingImage})`,
          backgroundSize: 'cover', 
          backgroundRepeat: 'no-repeat', 
        }}>
        <div className="nav-buttons">
          <i className="bi bi-arrow-left-circle back-button" onClick={handleGoBack}></i>
          <i className={`bi ${isFullScreen ? 'bi-fullscreen-exit' : 'bi-fullscreen'} fullscreen-button`} onClick={toggleFullScreen}></i>
        </div>
        <h1 className="text-80 text-center title-font-bold">Choose your sidekick</h1>
        <div className="selection-navigation">
          <div className="left-button" onClick={() => handleSwipe('left')}>
            <i className="bi bi-caret-left-fill"></i>
          </div>
          <div className="right-button" onClick={() => handleSwipe('right')}>
            <i className="bi bi-caret-right-fill"></i>
          </div>
        </div>
        <div className="character-display background">
          <img src={process.env.PUBLIC_URL + fgSettingImage} alt={"background"} />
        </div>
        <div className="character-display">
          <img src={process.env.PUBLIC_URL + shuffledHelpers[selectedHelperIndex].image} alt={shuffledHelpers[selectedHelperIndex].name} />
        </div>
        <h1 className="text-large2 title-font-bold">{shuffledHelpers[selectedHelperIndex].name}</h1>
        <h3 className="text-large3 title-font-bold">{shuffledHelpers[selectedHelperIndex].description}</h3>
        <button className="next-button" onClick={navigateToNext}>Next</button>
        <div className="pagination">
          {shuffledHelpers.map((_, index) => (
            <div key={index} className={`dot ${index === selectedHelperIndex ? 'active' : ''}`} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default HelperSelection;
