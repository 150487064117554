import React, { useState, useEffect } from 'react';
import { StoryAPI } from "../../api/StoryAPI";
import './WordStory.css';
import './StoryApp.css'; 

const WordStory: React.FC = () => {
  const [storyParts, setStoryParts] = useState([
    { text: "Once upon a time there was a", options: ["wise", "clever", "beautiful"] },
    //{ text: "princess. She lived a long way away in a", options: ["castle.", "ship.", "cave."] },
    //{ text: "It was her dream to one day go to", options: ["the moon.", "the north pole.", "Rome."] },
  ]);
  const [currentPartIndex, setCurrentPartIndex] = useState(0);
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [storyText, setStoryText] = useState('');
  const [isTextComplete, setIsTextComplete] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const currentPartWords = storyParts[currentPartIndex].text.split(' ');

    const displayWordByWord = () => {
      const currentPartWords = storyParts[currentPartIndex].text.split(' ');
      if (currentWordIndex < currentPartWords.length) {
        setStoryText((prev) => prev + ' ' + currentPartWords[currentWordIndex]);
        setCurrentWordIndex((prev) => prev + 1);
      } 
    };

    // Check if there are more words to display in the current part
    if (currentWordIndex < currentPartWords.length) {
      setIsTextComplete(false);
      const timer = setTimeout(displayWordByWord, 1000); // Delay of 1 second per word
      return () => clearTimeout(timer);
    } else {
      setIsTextComplete(true);
    }
  }, [currentWordIndex, currentPartIndex, storyParts]);

  const selectOption = async (option: string) => {
    const new_story_text = storyText + " " + option;
    setStoryText(prev => prev + ' ' + option);
    
    if (currentPartIndex === storyParts.length - 1) {
      console.log('getting next story parts from BE');
      try {
        setLoading(true);
        const response = await StoryAPI.postWordStory(new_story_text);
        setLoading(false);

        setStoryParts(prev => [...prev, 
          { text: response['section_1_text'], options: [response['section_1_option_1'], response['section_1_option_2'], response['section_1_option_3']]}, 
          { text: response['section_2_text'], options: [response['section_2_option_1'], response['section_2_option_2'], response['section_2_option_3']]}
        ]);
      } catch (error) {
        console.error('Error fetching next story parts:', error);
        setLoading(false);
      }
    }

    setCurrentPartIndex(prev => prev + 1);
    setCurrentWordIndex(0);
  };

  return (
    <div className="dimension-container">
      <div className="selection-container px-0"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/start_screen5.png)`,
          backgroundSize: 'cover', 
          backgroundRepeat: 'no-repeat', 
          backgroundPosition: 'center'
        }}>

        <div className="word-story-text-box">
          <p>{storyText}</p>
        </div>
        
        <div className="word-story-words">
          {storyText.split(' ').slice(-7).map((word, index) => (
              <button className={`btn btn-primary rectangle-button ${isTextComplete ? '' : 'animate-words'}`} key={index}>
                {word}
              </button>
          ))}
        </div>

        {storyParts[currentPartIndex].options.length > 0 && (
          <div className="word-story-option-buttons">
            {isTextComplete && storyParts[currentPartIndex].options.map((option, index) => (
              <button className="btn btn-primary rectangle-button-options" key={index} onClick={() => selectOption(option)}>
                {option}
              </button>
            ))}
          </div>
        )}

        {loading && <div>Loading next part of the story...</div>}
      </div>
    </div>
  );
};

export default WordStory;
