import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Container } from 'react-bootstrap';

  

function BingoIntro() {
  const navigate = useNavigate();
  const persons = ['Ashlyn', 'Claire', 'Jenny', 'Lauren', 'Leigh', 'Nathan'];

  const handleButtonClick = (person: string) => {
    navigate(`/holiday-bingo/${person.toLowerCase()}`);
  };

  return (
    <Container className="text-center">
      <div style={{height:"25vh"}}></div>
      <h1>Holiday Bingo</h1>
      <div className="d-grid gap-2 mt-4">
        {persons.map((person) => (
          <Button
            key={person}
            variant="primary"
            size="lg"
            onClick={() => handleButtonClick(person)}
          >
            {person}
          </Button>
        ))}
      </div>
    </Container>
  );
}

export default BingoIntro;
