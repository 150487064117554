import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useAppContext } from '../../AppContext';
import { StoryAPI } from "../../api/StoryAPI";
import './Feedback.css';
import './StoryApp.css'; 


const Feedback: React.FC = () => {
  
  const { storyId, isFullScreen, toggleFullScreen } = useAppContext();

  const [email, setEmail] = useState('');
  const [userAge, setUserAge] = useState('');
  const [feedback, setFeedback] = useState('');
  const [rating, setRating] = useState(3);

  const navigate = useNavigate();

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    console.log({ email, userAge, feedback, rating });
    const feedbackResponse = await StoryAPI.postFeedback(storyId, email, Number(userAge), feedback, rating);
    console.log('Reply from BE: ', feedbackResponse);
    navigate('/story');
  };

  const handleGoBack = () => {
    navigate("/story");
  }  

  const ratingFaces = ["😡", "🙁", "😐", "🙂", "😍"];

  return (
    <div className="dimension-container">
      <div className="selection-container px-0"
        style={{
          backgroundColor: 'lightgrey',
        }}>
        <div className="nav-buttons">
          <i className="bi bi-arrow-left-circle back-button" onClick={handleGoBack}></i>
          <i className={`bi ${isFullScreen ? 'bi-fullscreen-exit' : 'bi-fullscreen'} fullscreen-button`} onClick={toggleFullScreen}></i>
        </div>
        <h1 className="text-80 text-center title-font-bold">Feedback form</h1>
       
        <div className="form-group">
          <label htmlFor="emailInput">Email</label>
          <input 
            type="email" 
            className="form-control" 
            id="emailInput" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            placeholder="Enter your email" 
          />
        </div>

        <div className="form-group">
          <label htmlFor="ageInput">User age</label>
          <input 
            type="number" 
            className="form-control" 
            id="ageInput" 
            value={userAge} 
            onChange={(e) => setUserAge(e.target.value)} 
            placeholder="Enter your age" 
          />
        </div>

        <div className="form-group">
          <label htmlFor="feedbackTextarea">Feedback</label>
          <textarea 
            className="form-control feedback-textarea" 
            id="feedbackTextarea" 
            value={feedback} 
            onChange={(e) => setFeedback(e.target.value)} 
            placeholder="Enter your feedback here. Feel free to leaves suggestions for new characters, sidekicks, villains or settings."
          ></textarea>

        </div>

        <div className="rating-section">
          {[1,2,3,4,5].map((value) => (
            <button 
                type="button"
                key={value} 
                className={`rating-button btn ${rating === value ? 'btn-primary' : 'btn-outline-primary'}`} 
                onClick={() => setRating(value)}
                data-emoticon={ratingFaces[value - 1]}
              >
            </button>
          ))}
        </div>

        <button className="next-button" onClick={handleSubmit}>Submit</button>
      </div>
    </div>
  );
}

export default Feedback;
